import { useEffect, useState } from "react";
import { useRequestPost } from "hooks/useRequest";
// icons
import { IoClose } from "react-icons/io5";
import { MdRefresh } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FaCaretUp } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa6";
const RecommandKeywordModal = ({ variable, setVariable, modalBtn }) => {
  const [isShow, setIsShow] = useState(false);
  const [keywordInput, setKeywordInput] = useState("");
  const [createdKeyword, setCreatedKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [monthlyMobileQcCnt, setMonthlyMobileQcCnt] = useState("up"); // 모바일 검색량
  const [compareIdx, setcompareIdx] = useState("null"); // 모바일 클릭량

  const [senario, setSenario] = useState(1);
  const postKeyword = useRequestPost();
  const handleSubmit = () => {
    if (keywordInput) {
      const data = {
        hintKeywords: keywordInput,
        scenario: senario,
      };
      postKeyword.mutate({
        // 제목 생성 요청
        requestUrl: "/api/related-keywords",
        postData: data,
      });
    }
  };
  useEffect(() => {
    if (postKeyword.status === "success") {
      const keywordList = postKeyword?.data?.data?.keywordList;
      setCreatedKeyword(keywordList);
    }
  }, [postKeyword.status]);

  useEffect(() => {
    let newSenario = 1; // 기본값
    if (monthlyMobileQcCnt === "up" && !compareIdx) {
      newSenario = 1; // 모바일 검색량 높
    } else if (monthlyMobileQcCnt === "down" && !compareIdx) {
      newSenario = 2; // 모바일 검색량 낮
    } else if (!monthlyMobileQcCnt && compareIdx === "up") {
      newSenario = 3; // 경쟁률 높
    } else if (!monthlyMobileQcCnt && compareIdx === "down") {
      newSenario = 4; // 경쟁률 낮
    } else if (monthlyMobileQcCnt === "up" && compareIdx === "up") {
      newSenario = 5; // 모바일 검색량 높 경쟁률 높
    } else if (monthlyMobileQcCnt === "up" && compareIdx === "down") {
      newSenario = 6; // 모바일 검색량 높 경쟁률 낮
    } else if (monthlyMobileQcCnt === "down" && compareIdx === "up") {
      newSenario = 7; // 모바일 검색량 낮 경쟁률 높
    } else if (monthlyMobileQcCnt === "down" && compareIdx === "down") {
      newSenario = 8; // 모바일 검색량 낮 경쟁률 낮
    } else {
      newSenario = 1;
      return;
    }
    setSenario(newSenario);
  }, [monthlyMobileQcCnt, compareIdx]);
  const handleKeywordSelection = (keyword) => {
    setSelectedKeyword((prevSelected) =>
      prevSelected.includes(keyword)
        ? prevSelected.filter((item) => item !== keyword)
        : [...prevSelected, keyword]
    );
  };
  const handleMonthlyMobileQcCnt = (cnt) => {
    if (cnt === monthlyMobileQcCnt) setMonthlyMobileQcCnt(null);
    else {
      if (cnt === "up") {
        setMonthlyMobileQcCnt("up");
      } else if (cnt === "down") {
        setMonthlyMobileQcCnt("down");
      }
    }
  };
  const handleCompareIdx = (comparison) => {
    if (comparison === compareIdx) setcompareIdx(null);
    else {
      if (comparison === "up") {
        setcompareIdx("up");
      } else if (comparison === "down") {
        setcompareIdx("down");
      }
    }
  };
  // 시나리오 바뀔때마다 요청
  useEffect(() => {
    if (senario) handleSubmit();
  }, [senario]);

  // 키워드 선택시 요청
  const selectAllKeywords = () => {
    setSelectedKeyword(createdKeyword.map((el) => el.relKeyword));
  };
  const deselectAllKeywords = () => {
    setSelectedKeyword([]);
  };

  // 사용
  const handleFinalUse = () => {
    setVariable([...variable, ...selectedKeyword]);
    setIsShow(false);
    setSelectedKeyword([]);
  };
  return (
    <div>
      <div onClick={() => setIsShow(true)}>{modalBtn}</div>
      {isShow && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="fixed w-screen h-screen bg-[#00000050] left-0 top-0 z-40 flex justify-center items-center select-none"
        >
          <div className="p-4 rounded-lg bg-gradient-modal">
            <div className="flex justify-between p-2 text-sm font-bold">
              <div>키워드 추천</div>
              <IoClose onClick={() => setIsShow(false)} />
            </div>
            <div className="p-3 rounded-lg bg-text-white">
              <div className="w-[40rem] flex items-center justify-between bg-text-white border p-2 rounded-lg border-gray-gray">
                <input
                  placeholder="해방촌, 디자인, 트렌드"
                  type="text"
                  onChange={(e) => setKeywordInput(e.target.value)}
                  className="w-full focus:outline-none"
                />
                <div>
                  <IoSearch
                    onClick={handleSubmit}
                    className="w-5 h-5 text-gray-gray"
                  />
                </div>
              </div>
              {createdKeyword.length > 0 && (
                <div>
                  <div
                    className="flex items-center my-2 text-sm cursor-pointer"
                    onClick={() => {
                      selectedKeyword.length === createdKeyword.length
                        ? deselectAllKeywords()
                        : selectAllKeywords();
                    }}
                  >
                    {selectedKeyword.length === createdKeyword.length ? (
                      <MdCheckBox className="w-5 h-5 mr-1 text-main-pink" />
                    ) : (
                      <MdCheckBoxOutlineBlank className="w-5 h-5 mr-1 text-main-pink" />
                    )}
                    <span>전체선택</span>
                    <span className="ml-1 text-main-pink">
                      {selectedKeyword.length}
                    </span>
                  </div>
                  <div className="grid grid-cols-4 gap-1 text-sm text-center">
                    <div className="p-2 rounded-md bg-gray-sGray">키워드</div>
                    <div className="flex justify-between px-3 rounded-md bg-gray-sGray">
                      <div className="p-2"> 모바일 검색량</div>
                      <div className="flex-col p-1 text-gray-gray">
                        <FaCaretUp
                          className={
                            monthlyMobileQcCnt === "up"
                              ? "w-4 h-4 text-main-pink"
                              : "w-4 h-4"
                          }
                          onClick={() => handleMonthlyMobileQcCnt("up")}
                        />
                        <FaCaretDown
                          className={
                            monthlyMobileQcCnt === "down"
                              ? "w-4 h-4 text-main-pink mt-[-5px]"
                              : "w-4 h-4 mt-[-5px]"
                          }
                          onClick={() => handleMonthlyMobileQcCnt("down")}
                        />
                      </div>
                    </div>
                    <div className="p-2 rounded-md bg-gray-sGray">
                      모바일 클릭량
                    </div>

                    <div className="flex justify-between px-3 rounded-md bg-gray-sGray">
                      <div className="p-2">경쟁률</div>
                      <div className="flex-col p-1 text-gray-gray">
                        <FaCaretUp
                          className={
                            compareIdx === "up"
                              ? "w-4 h-4 text-main-pink"
                              : "w-4 h-4"
                          }
                          onClick={() => handleCompareIdx("up")}
                        />
                        <FaCaretDown
                          className={
                            compareIdx === "down"
                              ? "w-4 h-4 text-main-pink mt-[-5px]"
                              : "mt-[-5px] w-4 h-4"
                          }
                          onClick={() => handleCompareIdx("down")}
                        />
                      </div>
                    </div>
                  </div>
                  {createdKeyword.map((el, idx) => (
                    <div
                      key={idx}
                      className="grid grid-cols-4 gap-1 py-3 text-sm border-b border-gray-lGray"
                    >
                      <div className="flex items-center">
                        {selectedKeyword.includes(el.relKeyword) ? (
                          <MdCheckBox
                            className="w-5 h-5 mr-1 text-main-pink"
                            onClick={() =>
                              handleKeywordSelection(el.relKeyword)
                            }
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            className="w-5 h-5 mr-1 text-main-pink"
                            onClick={() =>
                              handleKeywordSelection(el.relKeyword)
                            }
                          />
                        )}
                        {el.relKeyword}
                      </div>
                      <div className="text-center">{el.monthlyMobileQcCnt}</div>
                      <div className="text-center">
                        {el.monthlyAveMobileClkCnt}
                      </div>
                      <div className="text-center">{el.compIdx}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex justify-center mt-2 text-sm">
              <button
                onClick={handleFinalUse}
                className="px-16 py-1 rounded-md bg-main-red text-text-white"
              >
                사용
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecommandKeywordModal;
