// api
import axios from "axios";
// hooks

const createAxiosInstance = () => {
  const TOKEN = localStorage.getItem("TOKEN");
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
    withCredentials: true,
  });
};

const instance = createAxiosInstance();

// export const setToken = (token) => {
//     localStorage.setItem('TOKEN', token);
//     instance.defaults.headers['Authorization'] = `Bearer ${token}`;
// };
// 240930. 요청 인터셉터 추가
instance.interceptors.request.use(
  (config) => {
    const TOKEN = localStorage.getItem("TOKEN");
    if (TOKEN) {
      config.headers["Authorization"] = `Bearer ${TOKEN}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 2. 응답 인터셉터 추가
instance.interceptors.response.use(
  (response) => {
    // 응답이 성공적인 경우 그대로 반환
    return response;
  },
  (error) => {
    // 3. 400 에러 코드 감지 및 처리
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 400)
    ) {
      // 여기서 400 에러에 대한 처리 로직을 추가하세요
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("TOKEN");

        const currentPath = window.location.pathname;
        // alert("로그인이 만료되었습니다.");
        window.location.href = "/landing"; // 페이지를 /login으로 이동
      }

      console.error("400 에러 감지:", error.response.data);
    }
    // 에러를 계속 던져서 호출한 곳에서 추가로 처리할 수 있게 함
    return Promise.reject(error);
  }
);

export default instance;
