// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setCookie, getCookie, removeCookie } from "hooks/useCookie";
// import { Cookies } from "react-cookie";

export default function Success() {
  const navigate = useNavigate();
  // 파라미터 관련
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const TOKEN = searchParams.get("token");

  useEffect(() => {
    if (TOKEN) {
      localStorage.setItem("TOKEN", TOKEN);
      window.setTimeout(() => {
        navigate("/", { replace: true });
        // window.location.reload();
      }, 200); // 새로고침 전에 약간의 지연을 추가
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TOKEN]);
  // []
  // );
  // const cookies = new Cookies();

  // 쿠키 관련
  // const onSet = () => {
  //     console.log('onSet');
  //     setCookie('cookieKey', 'cookieValue', {
  //         path: '/',
  //         secure: true,
  //         maxAge: 3000,
  //     });
  // };
  // const onGet = () => {
  //     const getVal = getCookie('cookieKey');
  //     const getTOKEN = getCookie('Authentication');
  //     console.log('getVal', getVal, "Authentication", getTOKEN);
  // };

  return (
    <div className="inset-0 flex justify-center items-center fixed z-10 bg-[rgb(0,0,0,0.3)]">
      <div className="w-fit m-2 p-5 z-[11] bg-white text-base lg:text-lg text-y-gold rounded-lg">
        {/* <div className="mb-5 font-bold text-center">[ ROADING ]</div> */}
        {/* <img
          src={plum_byte_logo}
          alt="plum_byte_logo"
          className="m-auto w-14"
        /> */}
        {/* <div>
                    <button
                        onClick={onSet}
                        type="button"
                        className="p-2 m-4 border border-main-pink"
                    >
                        set cookie
                    </button>
                    <button
                        onClick={onGet}
                        type="button"
                        className="p-2 m-4 border border-main-pink"
                    >
                        get cookie
                    </button>
                </div>{' '} */}
        <div className="mt-5 text-sm text-center text-y-brown">
          <div>로그인중입니다.</div>
          <div>잠시만 기다려 주세요</div>
        </div>
      </div>
    </div>
  );
}
