import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateUtils";
const ContentsList = ({ seq, title, createdAt, count, isPinned }) => {
  return (
    <Grid
      container
      spacing={1}
      className="border-b text-xs items-center h-[45px]"
    >
      <Grid xs={0.8} className="text-center ">
        {isPinned === true ? (
          <div className="bg-main-red/10 text-main-red m-auto w-[37px] h-[24px] rounded-md p-1">
            공지
          </div>
        ) : (
          <div>{seq}</div>
        )}
      </Grid>

      <Grid xs={7.6}>
        <Link to={`/notification/${seq}`} className="ml-2 ">
          {title}
        </Link>
      </Grid>

      <Grid xs={1.8} className="text-center">
        <div>{formatDate(createdAt)}</div>
      </Grid>
      <Grid xs={1.8} className="text-center">
        <div>{count}</div>
      </Grid>
    </Grid>
  );
};

export default ContentsList;
