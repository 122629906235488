import React, { useState, useEffect } from "react";

const TimeLoadingModal = ({ second = 0 }) => {
  const [remainingTime, setRemainingTime] = useState(second);

  useEffect(() => {
    // 1초 간격으로 remainingTime을 감소시키는 타이머 설정
    if (second > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);

      // 컴포넌트가 언마운트되거나 시간이 0이 되면 타이머 정리
      return () => clearInterval(timer);
    } else return second;
  }, []);

  return (
    <div className="w-screen h-screen bg-[#00000040] z-50 fixed top-0 left-0 flex justify-center items-center">
      <div className="relative text-[14px] min-w-[170px] min-h-[100px] flex flex-col justify-center items-center bg-gradient-modal  rounded-[10px]">
        <div>콘텐츠 생성까지 </div>
        <div>
          <span className="font-bold border">약 {remainingTime}초</span>{" "}
          남았습니다.
        </div>
      </div>
    </div>
  );
};

export default TimeLoadingModal;
