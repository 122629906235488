/**
 * 주어진 날짜 문자열에서 날짜 부분만 잘라 반환하는 함수

 */

export function formatDate(date) {
  if (date) {
    let getDate = date.split("T");
    return getDate[0];
  } else {
    return null;
  }
}
