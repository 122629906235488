import React, { useState, useEffect, useCallback } from "react";
import { useRequestGet } from "hooks/useRequest";
import ContentsList from "./ContentsList";
import Grid from "@mui/material/Unstable_Grid2";
import Pagination from "components/Pagination/Pagination";
import { IoWarning } from "react-icons/io5";
import Loading from "components/Loading/Loading";
import CategoryDropdown from "./CategoryDropdown";
function ContentsBox({ searchTerm }) {
  const [feedBackData, setFeedBackData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getFeedBack = useRequestGet(
    `/api/feedback?feedbackType=${category}&search=${searchTerm}&page=${page}&size=20`,
    ["getFeedBack", category, searchTerm, page]
  );
  const itemList = [
    { label: "전체", value: "ALL" },
    { label: "서비스제안", value: "SERVICE_SUGGESTION" },
    { label: "오류접수", value: "BUG_REPORT" },
    { label: "자유의견", value: "FREE_OPINION" },
  ];
  const fetchData = useCallback(() => {
    if (getFeedBack.status === "success") {
      setFeedBackData(getFeedBack.data.data.data);
      setTotalPages(getFeedBack.data.data.pageInfo.totalPage);
      setIsLoading(false);
    } else if (getFeedBack.status === "error") {
      setIsLoading(false);
    }
  }, [getFeedBack]);
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [getFeedBack.status, fetchData]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {feedBackData.length === 0 ? (
            <div className="text-center pt-56">
              <div className=" m-auto w-14 h-14 rounded-md bg-main-red mb-5 p-1">
                <IoWarning className="w-12 h-12 text-text-white " />
              </div>
              <p className="text-main-red text-lg">
                {searchTerm === ""
                  ? "게시글이 없습니다."
                  : "검색 결과가 존재하지 않습니다"}
              </p>
            </div>
          ) : (
            <div>
              <Grid container spacing={1} className="text-center text-xs">
                <Grid xs={0.6}>
                  <div className="bg-gray-sGray rounded-md p-3">NO</div>
                </Grid>
                <Grid xs={2}>
                  <CategoryDropdown
                    category={category}
                    setCategory={setCategory}
                    background={"#F1F3F5"}
                    itemList={itemList}
                  />
                </Grid>
                <Grid xs={5.8}>
                  <div className="bg-gray-sGray rounded-md p-3">제목</div>
                </Grid>
                <Grid xs={1.2}>
                  <div className="bg-gray-sGray rounded-md p-3">닉네임</div>
                </Grid>
                <Grid xs={1.2}>
                  <div className="bg-gray-sGray rounded-md p-3">작성일</div>
                </Grid>
                <Grid xs={1.2}>
                  <div className="bg-gray-sGray rounded-md p-3">조회수</div>
                </Grid>
              </Grid>
              {feedBackData.map((item, index) => {
                return (
                  <ContentsList
                    key={index}
                    seq={item.seq}
                    feedbackType={item.feedbackType}
                    title={item.feedbackTitle}
                    userName={item.userName}
                    createdAt={item.createdAt}
                    count={item.feedbackViewCount}
                    commentCount={item.commentCount}
                  />
                );
              })}
              <div className="p-5">
                <Pagination
                  totalPage={totalPages}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ContentsBox;
