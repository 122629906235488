import React, { useState } from "react";
import { formatDate } from "utils/DateUtils";
import EditBtn from "components/Buttons/EditBtn";
import DeleteBtn from "components/Buttons/DeleteBtn";
import { useNavigate } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
import DeleteModal from "components/Modals/DeleteModal";
const ContentView = ({
  title,
  userName,
  createdAt,
  count,
  noticeHtml,
  noticeseq,
  handleDelete,
  isPinned,
  userSeq,
}) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pendingDeleteId, setPendingDeleteId] = useState(null);
  const handleEditClick = () => {
    navigate(`/notification/edit/${noticeseq}`); // 수정 페이지로 이동
  };
  let getUserInfo = useRequestGet("/api/user", "userInfo");

  const handleDeleteClick = () => {
    setPendingDeleteId(noticeseq);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (pendingDeleteId) {
      handleDelete(pendingDeleteId);
      setPendingDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setPendingDeleteId(null);
  };
  return (
    <div>
      <div className="flex justify-between bg-gray-sGray py-6 px-4 rounded-lg">
        <div className="flex gap-2 items-center">
          {isPinned === true && (
            <div className="bg-main-red/10 text-xs text-main-red m-auto rounded-md py-1 px-1.5">
              공지
            </div>
          )}

          <div>{title}</div>
        </div>
        <div className="flex gap-2 text-gray-gray items-center text-sm">
          <p>{formatDate(createdAt)}</p>
          <p>조회수 {count}</p>
          {userSeq === getUserInfo?.data?.data?.seq ? (
            <div className="flex gap-1 ml-2">
              <EditBtn editFunc={handleEditClick} />
              <DeleteBtn deletFunc={handleDeleteClick} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="min-h-72 border-b">
        <div className="p-6 text-text-dGray text-sm">{noticeHtml}</div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="공지사항을 삭제하시겠습니까?"
        />
      )}
    </div>
  );
};

export default ContentView;
