import React, { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";

const AgeRangeSlider = ({ ageRange, setAgeRange }) => {
  const MIN = 0;
  const MAX = 100;
  const [values, setValues] = useState([MIN, MAX]);

  useEffect(() => {
    if (ageRange) {
      setValues(ageRange);
    }
  }, [ageRange]);

  const handleChange = (newValues) => {
    setValues(newValues);
    if (setAgeRange) {
      setAgeRange(newValues);
    }
  };

  return (
    <div className="flex flex-col items-center w-full p-4">
      <Range
        values={values}
        step={5}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => {
          const { key, ...restProps } = props;
          return (
            <div
              {...restProps}
              style={{
                ...restProps.style,
                height: "8px",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  background: getTrackBackground({
                    values,
                    colors: ["#ECEFF1", "#F33956", "#ECEFF1"],
                    min: MIN,
                    max: MAX,
                  }),
                  borderRadius: "4px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: `${((values[1] - values[0]) / (MAX - MIN)) * 100}%`,
                  left: `${(values[0] / MAX) * 100}%`,
                  background:
                    "repeating-linear-gradient(45deg, #F33956, #F33956 3px, #F77489 3px, #F77489 6px)",
                  borderRadius: "4px",
                }}
              />
              {React.Children.map(children, (child, index) =>
                React.cloneElement(child, { key: index })
              )}
            </div>
          );
        }}
        renderThumb={({ props, isDragged }) => {
          const { key, ...restProps } = props;
          return (
            <div
              {...restProps}
              style={{
                ...restProps.style,
                height: "25px",
                width: "25px",
                borderRadius: "50%",
                backgroundColor: "#F33956",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
              }}
            >
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  backgroundColor: isDragged ? "#F33956" : "#fff",
                }}
              />
            </div>
          );
        }}
      />
      <div className="flex justify-between w-full px-2 mt-4">
        <span>{values[0]}세</span>
        <span>{values[1]}세</span>
      </div>
    </div>
  );
};

export default AgeRangeSlider;
