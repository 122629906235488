/* eslint-disable jsx-a11y/heading-has-content */
// hooks
import { useRef } from "react";
// react-markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// icons
import { FaRegCopy } from "react-icons/fa";
// Components
import BasicTooltip from "components/ToolTips/BasicToolTips";
import { toast } from "react-toastify"; // Toastify import

const CreatedCard = ({ contents }) => {
  const landingRef = useRef();
  const { post, image } = contents;
  const contentTitle = post?.value?.split("\n")[0];
  const TITLE_LEN = contentTitle?.length;
  const contentText = post.value.slice(TITLE_LEN);
  // console.log(contentTitle);
  const handleCopy = async () => {
    const textImage = image.imageS3Url;
    const splitedText = post?.value?.split("\n");

    const taggedText = splitedText
      .slice(1)
      .map((line) => {
        const splitedWord = line.split(" ");
        const firstKeyword = splitedWord.splice(0, 1)[0];
        const joinedSentence = splitedWord.join(" ");
        switch (firstKeyword) {
          case "#":
            return `<h1>${joinedSentence}</h1>`;
          case "##":
            return `<h2>${joinedSentence}</h2>`;
          case "###":
            return `<h3>${joinedSentence}</h3>`;
          case "####":
            return `<h4>${joinedSentence}</h4>`;
          case "#####":
            return `<h5>${joinedSentence}</h5>`;
          case "-":
          case "*":
            return `<li>${joinedSentence}</li>`;
          case "":
            return ``;
          default:
            return `<div>
            <p>${joinedSentence}</p>
            <div>&nbsp;</div>
            </div>`;
        }
      })
      .join("");
    const htmlContent = ` <div><div><h1>${contentTitle.replace(
      /[#\*]/g,
      ""
    )}</h1><img src="${textImage}" alt="${
      image.seq
    }-Image" /></div>${taggedText}</div>`;
    try {
      const blob = new Blob([htmlContent], { type: "text/html" });
      const clipboardItem = new ClipboardItem({ "text/html": blob });
      await navigator.clipboard.write([clipboardItem]);
      toast.success("클립보드에 복사되었습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("복사 실패:", navigator.clipboard.writeText(htmlContent));
    }
  };
  return (
    <>
      {post && image && (
        <div className="max-w-[756px] rounded-md" ref={landingRef}>
          <div className="flex justify-between w-full mb-4 items-top">
            <div
              className="text-2xl font-bold"
              style={{ wordBreak: "keep-all" }}
            >
              {contentTitle && contentTitle.replace(/[#\*]/g, "")}
            </div>
            <div className="m-2">
              <BasicTooltip
                title="Copy"
                placement="top"
                contents={
                  <FaRegCopy
                    className="w-4 h-4 text-gray-dGray"
                    onClick={handleCopy}
                  />
                }
              />
            </div>
          </div>
          <img
            src={image.imageS3Url}
            alt="temp"
            className="w-full m-auto rounded-xl"
          />
          <div className="mt-8">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: (props) => (
                  <h3 className="my-4 text-2xl font-bold" {...props}></h3>
                ),
                h2: (props) => (
                  <h3 className="my-4 text-xl font-bold" {...props}></h3>
                ),
                h3: (props) => (
                  <h3 className="my-4 text-lg font-bold" {...props}></h3>
                ),
                h4: (props) => (
                  <h3 className="my-4 font-bold text-md" {...props}></h3>
                ),
                p: (props) => (
                  <p className="my-2 text-base font-normal" {...props}></p>
                ),
              }}
            >
              {contentText && contentText}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatedCard;
