// hooks
import { useLocation, useNavigate } from "react-router-dom";
// images
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";

const Failure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorMessage = searchParams.get("error");
  return (
    <div className="inset-0 flex justify-center items-center fixed z-10 bg-[rgb(0,0,0,0.3)]">
      <div className="w-fit m-2 p-5 z-[11] bg-white text-base lg:text-lg text-y-gold rounded-lg">
        <div className="font-bold text-center mb-5">[ ERROR ]</div>
        <img
          src={plum_byte_logo}
          alt="plum_byte_logo"
          className="w-14 m-auto"
        />
        <div className="mt-5 text-center">
          <div>
            {errorMessage === "Duplicate Email Provider"
              ? "중복된 이메일 입니다"
              : ""}
          </div>
          <div>올바른 계정으로 로그인 바랍니다.</div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={() => navigate("/login")}
            className="border bg-main-purple text-text-white px-3 py-2 rounded-md"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Failure;
