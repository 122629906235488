import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
const NavigateHeader = () => {
  const navigate = useNavigate();
  const editorLocationArr = JSON.parse(localStorage.getItem("navArr"));
  const navRecord = JSON.parse(localStorage.getItem("navRecord"));
  return (
    <div className="text-start w-full text-text-gray mt-[-10px] ">
      {editorLocationArr.map(
        (el, idx) =>
          navRecord[el] && (
            <>
              <button
                onClick={() =>
                  navigate(Number(`-${editorLocationArr.length - idx}`))
                }
                className="p-1 text-base rounded-lg hover:bg-gray-lGray"
              >
                <span>
                  {idx === 0 && el === "templates" ? "템플릿" : navRecord[el]}
                </span>
              </button>
              <span className="px-[2px]">/</span>
            </>
          )
      )}
      <button
        type="button"
        className="font-bold rounded-lg text-lg text-text-black ml-[2px]"
      >
        {navRecord["templates"]}
      </button>
    </div>
  );
};

export default NavigateHeader;

export const locationToKR = (location) => {
  switch (location) {
    case "templates":
      return "템플릿";
    case "template":
      return "템플릿생성";
    case "project":
      return "프로젝트";
    case "content":
      return "콘텐츠";
    default:
      return location;
  }
};
