import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SettingInput from "components/Inputs/SettingInput";

import SettingTextarea from "pages/Setting/components/SettingTextarea";
import PrimaryBtn from "components/Buttons/PrimaryBtn";
import DefaultBtnSm from "components/Buttons/DefaultBtn";
import {
  useRequestPost,
  useRequestGet,
  useRequestPatch,
} from "hooks/useRequest";
import NoticeCheckbox from "../components/NoticeCheckbox";

// common
import { toast } from "react-toastify"; // Toastify import

function NoticePost() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPinned, setIsPinned] = useState(false); // Initial state for checkbox
  const postMutation = useRequestPost();
  const patchMutation = useRequestPatch();
  const noticeData = useRequestGet(
    id ? `/api/notice/${id}` : null,
    id ? `notice-${id}` : null
  );

  useEffect(() => {
    if (id && noticeData?.status === "success" && noticeData?.data) {
      setIsEditMode(true);
      const { noticeTitle, noticeHtml, isPinned } = noticeData.data.data;

      setTitle(noticeTitle);
      setContent(noticeHtml);
      setIsPinned(isPinned); // Load initial checkbox state
    }
  }, [id, noticeData?.status, noticeData?.data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (!content) {
      toast.error("내용을 입력해주세요.");

      return;
    }
    const data = {
      isPinned: isPinned,
      noticeTitle: title,
      noticeHtml: content,
      s3Urls: [],
    };

    try {
      if (isEditMode) {
        await patchMutation.mutate({
          requestUrl: `/api/notice/${id}`,
          patchData: data,
        });
      } else {
        await postMutation.mutate({
          requestUrl: "/api/notice",
          postData: data,
        });
      }
    } catch (error) {
      console.error("Error submitting notice form:", error);
    }
  };

  useEffect(() => {
    if (
      postMutation.status === "success" ||
      patchMutation.status === "success"
    ) {
      navigate("/notification", {
        state: { newnoticeAdded: true },
      });
    } else if (
      postMutation.status === "pending" ||
      patchMutation.status === "pending"
    ) {
      console.log("게시글 작성 중입니다.");
    }
  }, [patchMutation.status, navigate, postMutation.status]);

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper">
        <div className="title">
          <h3 className="text-lg font-semibold">공지사항</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex mt-10 gap-10 w-full">
            <div className="w-[99px] flex flex-wrap">
              <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
                고정여부
              </p>
              <div className="items-center">
                <NoticeCheckbox
                  label={"고정함"}
                  name={"isPinned"}
                  checked={isPinned}
                  onChange={() => setIsPinned(!isPinned)} // Toggle checkbox state
                />
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between">
                <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
                  제목
                </p>
              </div>
              <SettingInput
                type={"text"}
                value={title}
                setValue={setTitle}
                placeholder={""}
              />
            </div>
          </div>
          <div className="mt-10">
            <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
              내용
            </p>
            <div>
              <SettingTextarea
                setValue={setContent}
                value={content}
                rows="10"
                type="default"
              />
            </div>
          </div>

          <div className="flex gap-2 mt-5 justify-center">
            <PrimaryBtn
              type="button"
              content="작성취소"
              btnClickFunc={() => navigate(-1)}
            />
            <DefaultBtnSm
              type="submit"
              content="작성 완료"
              btnClickFunc={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default NoticePost;
