import React, { useState } from 'react';
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiMinus } from "react-icons/ti";
import { TiPlus } from "react-icons/ti";
import { FaRegCopy } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify"; // Toastify import

const iconBgColor = {
    'fi-rr-caret-up': 'bg-[#F3395610] text-[#F33956] px-2 py-1',
    'fi-rr-caret-down': 'bg-[#9C1AD410] text-[#9C1AD4] px-2 py-1',
    'fi-rr-minus-small': 'bg-[#F1F3F5] text-[#495057] px-2 py-1',
    'fi-rr-plus-small': 'bg-[#F33956] text-white px-2 py-1',
};

const iconMatcher = (icon) => {
    return iconBgColor[icon] || '';
};

const KeywordCard = ({ ranking, subject, icon, link }) => {



    const handleCopyClick = () => {
        navigator.clipboard.writeText(subject).then(() => {

            toast.success("클립보드에 복사 되었습니다.",
            );
        }).catch(err => {
            console.error('클립보드 복사에 실패했습니다.', err);
        });
    };

    return (
        <div className="flex items-center justify-between p-4 border border-transparent hover:border-main-pink hover:border-1 rounded-lg">
            <div className="flex items-center space-x-2">
                <div className="text-base font-bold text-main-red">
                    {String(ranking).padStart(2, '0')}
                </div>
                <div className={`flex items-center ${iconMatcher(icon)} rounded-md`}>
                    {icon === 'fi-rr-caret-up' && <TiArrowSortedUp />}
                    {icon === 'fi-rr-caret-down' && <TiArrowSortedDown />}
                    {icon === 'fi-rr-minus-small' && <TiMinus />}
                    {icon === 'fi-rr-plus-small' && <TiPlus />}
                </div>
                <div className="text-base font-semibold text-black">{subject}</div>
            </div>
            <div className="flex items-center space-x-2">
                <div className="relative group">
                    <FaRegCopy onClick={handleCopyClick} className="cursor-pointer text-gray-gray w-[15px] h-[15px]" />
                    <div className="absolute bottom-0 mb-7 hidden group-hover:block w-40 bg-back-dGray text-white text-xs rounded py-1 px-1 text-center z-50 ml-[-70px]">
                        해당 순위 키워드 복사
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                    </div>
                </div>
                <a
                    target="_blank"
                    href={`https://search.naver.com/search.naver?where=news&ie=utf8&sm=tab_jum&query=${subject}`}
                    className="text-sm px-2 py-1 rounded-md text-main-pink hover:bg-gray-sGray cursor-pointer"
                >
                    기사 바로가기
                </a>
            </div>
        </div>
    );
};


//console.log(keywordData.timeData);


export default KeywordCard;