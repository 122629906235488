import { useEffect, useState } from "react";
import { useFormDataPost } from "hooks/useRequest";
// common
import ImageDragDrop from "components/ImageDragDrop";
import { toast } from "react-toastify"; // Toastify import

// icons
import { IoClose } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";

const ImageUploadModal = ({ seq, setImageUploadModal }) => {
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [fileImg, setFileImg] = useState(null);
  const [previewImgLists, setPreviewImgLists] = useState([]);
  const [fileImgLists, setFileImgLists] = useState([]);

  const handleImageModalClick = (e) => {
    e.stopPropagation();
  };

  // 이미지 추가
  useEffect(() => {
    if (fileImg && previewImg) {
      setFileImgLists((prev) => [...prev, fileImg]);
      setPreviewImgLists((prev) => [...prev, previewImg]);
      setFileImg(null);
      setPreviewImg(null);
      // 현재 추가된 이미지를 미리보기로 설정
      setSelectedIdx(fileImgLists.length);
    }
  }, [fileImg, previewImg, fileImgLists.length]);

  // 이미지 미리보기
  const handlePreviewClick = (idx) => {
    setSelectedIdx(idx);
    setPreviewImg(previewImgLists[idx]);
  };

  // 이미지 삭제
  const handleDelete = (idx) => {
    const updatedPreviewImgLists = previewImgLists.filter((_, i) => i !== idx);
    const updatedFileImgLists = fileImgLists.filter((_, i) => i !== idx);

    setPreviewImgLists(updatedPreviewImgLists);
    setFileImgLists(updatedFileImgLists);

    if (selectedIdx === idx) {
      setPreviewImg(null);
      setSelectedIdx(null);
    } else if (selectedIdx > idx) {
      setSelectedIdx((prev) => prev - 1);
    }
  };

  // 이미지 업로드 요청
  const postImage = useFormDataPost();
  const handleMedia = () => {
    // fileImgLists에 쌓인 이미지들을 업로드
    const formData = new FormData();
    fileImgLists.forEach((file) => {
      formData.append("images", file);
    });

    // 업로드 요청 함수 호출
    postImage.mutate({
      requestUrl: `/api/project/${seq}/images`,
      postData: formData,
    });
  };

  useEffect(() => {
    if (postImage.status === "success") {
      toast.success("프로젝트 이미지가 등록되었습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setImageUploadModal(false);
    }
  }, [postImage.status]);

  return (
    <div className="select-none">
      <div
        className="w-screen h-screen left-0 top-0 z-40 fixed bg-[#00000050]"
        onClick={(e) => {
          e.stopPropagation();
          setImageUploadModal(false);
        }}
      ></div>

      <div className="fixed z-40 rounded-lg bg-gradient-modal  bottom-5">
        <div
          onClick={handleImageModalClick}
          className="p-3 m-auto w-[40rem] h-[24rem]"
        >
          <div className="flex items-center justify-between mb-2">
            <div className="text-xs font-bold">프로젝트 이미지</div>
            <IoClose onClick={() => setImageUploadModal(false)} />
          </div>
          <div className="flex">
            <div className="w-full h-[18rem] overflow-y-scroll bg-text-white rounded-xl p-2">
              {fileImgLists.map((el, idx) => (
                <div
                  key={idx}
                  className={
                    selectedIdx === idx
                      ? "flex justify-between items-center p-2 mb-2 bg-text-white border border-main-pink font-bold rounded-lg"
                      : "flex justify-between items-center p-2 mb-2 bg-gray-sGray rounded-lg"
                  }
                >
                  <span
                    className="w-full text-sm rounded-md cursor-pointer"
                    onClick={() => handlePreviewClick(idx)}
                  >
                    {el.name}
                  </span>
                  {selectedIdx === idx && (
                    <button
                      className="ml-2 text-gray-gray"
                      onClick={() => handleDelete(idx)}
                    >
                      <RiDeleteBin6Line className="mr-1" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="w-full pl-2 h-[18rem]">
              <div className="relative flex items-center justify-center w-full h-full border-2 border-dashed rounded-lg border-gray-gray">
                <ImageDragDrop
                  fileImg={fileImg}
                  setFileImg={setFileImg}
                  previewImg={previewImgLists[selectedIdx]}
                  setPreviewImg={setPreviewImg}
                  dragStatePlace={
                    <div className="bg-[#eb228320] rounded-lg w-full h-full absolute left-0 top-0 flex justify-center items-center text-main-pink text-lg">
                      Drop Here
                    </div>
                  }
                  dropPlace={
                    <div className="flex flex-col items-center justify-center text-gray-dGray">
                      <div className="text-xs text-center">
                        이미지 선택 및 드래그하거나
                      </div>
                      <div className="text-xs text-center">
                        <span className="font-bold border-b text-main-pink border-main-pink">
                          첨부파일
                        </span>
                        을 업로드해주세요
                      </div>
                      <button className="px-2 py-1 mt-4 text-sm border rounded-lg border-gray-lGray">
                        File Upload
                      </button>
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-3">
            <button
              onClick={handleMedia}
              className="flex items-center px-8 py-1 border rounded-lg bg-main-red text-text-white"
            >
              적용
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
