const SettingInput = ({
  type,
  value,
  setValue,
  placeholder,
  onEnter,
  disabled = false,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newKeyword = event.target.value.trim();
      if (newKeyword !== "") {
        onEnter?.(newKeyword);
        event.target.value = "";
      }
    }
  };
  // inputType ,state, setState, placeholder
  return (
    <>
      {disabled ? (
        <input
          disabled
          type={type}
          value={value}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          className={
            "border bg-gray-sGray w-full text-sm rounded-lg p-2.5 border-gray-gray text-text-gray"
          }
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={
            "focus:outline-main-red focus:font-semibold placeholder:text-text-gray rounded-lg text-sm w-full bg-white p-2.5"
          }
        />
      )}
    </>
  );
};

export default SettingInput;
