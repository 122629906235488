// hooks
import { useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
// common components

import NavigateHeader from "layout/NavigateHeader";
// utils
import { templateQuestions } from "utils/TemplateQuestions";
import { templateIconMatcher } from "utils/TemplateIconMatcher";
import color_card_small from "assets/template/color_card_small.png";
import dream_card_small from "assets/template/dream_card_small.png";
// icons

import { FiFilePlus } from "react-icons/fi";

const Templates = () => {
  const locationURL = useLocation().pathname;
  const locationArr = locationURL.split("/").slice(1);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const navRecord = JSON.parse(localStorage.getItem("navRecord"));
  const [selectedTemplate, setSlectedTemplate] = useState(null);
  const handleSelectTemplate = (el) => {
    setSlectedTemplate(el);
    if (locationArr[0] === "templates") {
      localStorage.setItem(
        "navRecord",
        JSON.stringify({ templates: el.templateKR })
      );
    } else {
      localStorage.setItem(
        "navRecord",
        JSON.stringify({
          ...(navRecord && navRecord),
          templates: el.templateKR,
        })
      );
    }
    if (projectSeq)
      navigate(`/project/content/templates/template?projectSeq=${projectSeq}`, {
        state: el,
      });
    else navigate(`/templates/project?template=${el.templateEN}`);
  };
  // 템플릿 셔플
  // "dreamInterpretation"와 "expressingMoodWithColor" 템플릿 분리
  const specialTemplates = templateQuestions.filter(
    (template) =>
      template.templateEN === "dreamInterpretation" ||
      template.templateEN === "expressingMoodWithColor"
  );

  // 나머지 템플릿들
  const otherTemplates = templateQuestions.filter(
    (template) =>
      template.templateEN !== "dreamInterpretation" &&
      template.templateEN !== "expressingMoodWithColor"
  );

  // 다른 템플릿 사이에 랜덤으로 삽입
  function insertSpecialTemplates(otherTemplates, specialTemplates) {
    const result = [...otherTemplates];
    specialTemplates.forEach((template) => {
      const randomIndex = Math.floor(Math.random() * (result.length + 1));
      result.splice(randomIndex, 0, template);
    });
    return result;
  }

  const finalTemplateQuestions = insertSpecialTemplates(
    otherTemplates,
    specialTemplates
  );

  return (
    <div className="pb-10 select-none wrapper">
      <div className="flex flex-wrap justify-center cherry-wrapper">
        <NavigateHeader />

        {/* {!selectedTemplate ? ( */}
        <div className="flex flex-wrap mt-3 w-fit gap-4">
          {finalTemplateQuestions &&
            finalTemplateQuestions.map((el, idx) => (
              <div
                key={idx}
                className="border-[1px] inline-block bg-text-white p-[23px] pb-5 rounded-[18px] border-gray-lGray w-[215px] h-[215px] relative z-[10] bg-cover"
                style={{
                  backgroundImage:
                    el.tag === "무료" && el.templateKR === "감정 팔레트"
                      ? `url(${color_card_small})`
                      : el.tag === "무료" &&
                        el.templateKR === "어제 본 꿈 이야기"
                      ? `url(${dream_card_small})`
                      : "none",
                }}
              >
                <div className="flex flex-col justify-between h-full">
                  <div className="flex justify-between ">
                    <img
                      alt={el.templateEN}
                      src={templateIconMatcher[el.templateEN]}
                      className="w-[40px] h-[40px]"
                    />
                    <div
                      className={`${
                        el.tag === "SNS"
                          ? "text-main-red bg-[#F3395615]"
                          : el.tag === "글쓰기"
                          ? "text-main-purple bg-[#9C1AD420]"
                          : el.tag === "마케팅"
                          ? "text-text-dGray bg-[#acb5bd25]"
                          : el.tag === "계획"
                          ? "text-sub-skyBlue bg-[#28cce520]"
                          : el.tag === "무료" && el.templateKR === "감정 팔레트"
                          ? "bg-text-white text-main-pink"
                          : "bg-main-pink text-text-white"
                      }  text-sm h-fit py-1 px-2 w-fit font-semibold rounded-md`}
                    >
                      {el.tag}
                    </div>
                  </div>
                  <div className="flex flex-col justify-between">
                    <div>
                      <div className="flex items-start justify-start text-sm font-bold">
                        <div
                          className={`mr-2 mt-[-3px] text-base ${
                            el.tag === "무료"
                              ? "text-text-white"
                              : "text-text-black"
                          }`}
                          style={{ wordBreak: "keep-all" }}
                        >
                          {el.templateKR}
                        </div>
                        {/* {el.tag !== "무료" && (
                          <ToolTips
                            contents={
                              <BsQuestionSquare className="text-main-pink" />
                            }
                            placement="top-end"
                            title={el.description}
                          />
                        )} */}
                      </div>
                      <div
                        className={`text-start text-sm ${
                          el.tag === "무료"
                            ? "text-text-white"
                            : "text-gray-dGray"
                        }`}
                        style={{ wordBreak: "keep-all" }}
                      >
                        {el.description}
                      </div>
                    </div>
                    <button
                      onClick={() =>
                        el.templateEN === "expressingMoodWithColor" ||
                        el.templateEN === "dreamInterpretation"
                          ? navigate("/")
                          : handleSelectTemplate(el)
                      }
                      className={`mr-2 text-sm mt-3 hover:bg-gray-sGray hover:text-main-red w-full border rounded-lg active:text-text-white active:bg-main-red ${
                        el.tag === "무료"
                          ? "border-text-white py-[6px] text-text-white"
                          : "border-gray-lGray py-[6px]"
                      }`}
                    >
                      바로 시작하기
                    </button>
                  </div>
                </div>
              </div>
            ))}
          <button
            onClick={() => navigate("/feedBack/post")}
            className="m-[7px] flex flex-col justify-center border-dashed items-center border-[1.5px] border-gray-gray text-sm text-gray-gray  rounded-[18px] w-[215px] h-[215px] "
          >
            <FiFilePlus className="w-5 h-5 mt-3 mb-1" />
            <div>이런기능도</div>
            <div>만들어주세요!</div>
          </button>
        </div>
        {/* ) : (
           <DefiniteTemplate selectedTemplate={selectedTemplate} />
         )} */}
      </div>
    </div>
  );
};

export default Templates;
