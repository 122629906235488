import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export default function Pagination({ page, totalPages, setPage }) {
  const Pagination = new Array(totalPages).fill(1);
  return totalPages < 2 ? null : (
    <div className="flex justify-center items-center mt-2">
      <button
        className="pr-2  w-8 h-8  mx-0.5 text-md"
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      >
        <FaAngleLeft />
      </button>
      {Pagination.map((el, idx) => {
        return (
          <button
            key={idx}
            value={el + idx}
            onClick={(e) => {
              setPage(Number(e.currentTarget.value));
            }}
            className={`w-[30px] h-[30px] text-md mx-2 ${
              page === el + idx
                ? "text-text-white bg-main-red rounded-full"
                : "text-black"
            }
            ${
              page < 3
                ? el + idx < 6
                  ? ""
                  : "hidden"
                : page === el + idx - 2 ||
                  page === el + idx - 1 ||
                  page === el + idx ||
                  page === el + idx + 1 ||
                  page === el + idx + 2
                ? ""
                : page >= totalPages - 2
                ? el + idx > totalPages - 5
                  ? ""
                  : "hidden"
                : "hidden"
            }`}
          >
            {el + idx}
          </button>
        );
      })}
      <button
        className="pl-4  w-10 h-10  mx-0.5 text-md"
        onClick={() => {
          if (totalPages > page) {
            setPage(page + 1);
          }
        }}
      >
        <FaAngleRight />
      </button>
    </div>
  );
}
