export const getGenderText = (gender) => {
  switch (gender) {
    case "male":
      return "남";
    case "female":
      return "여";
    default:
      return "모두";
  }
};
