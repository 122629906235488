import React, { useState } from "react";
import { LuSearch } from "react-icons/lu";

function SearchInput({ setSearchTerm }) {
  const [input, setInput] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = () => {
    setSearchTerm(input);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div className="relative">
      <input
        type="text"
        value={input}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)} // 포커스 상태 변경
        onBlur={() => setIsFocused(false)} // 포커스 상태 변경
        onChange={(e) => setInput(e.target.value)}
        placeholder="제목을 검색헤주세요"
        className={
          "focus:outline-main-red placeholder:text-text-black rounded-xl text-sm w-[400px] bg-text-white px-3 py-2 border"
        }
      />
      <button
        type="button"
        onClick={handleSearch}
        className={
          isFocused
            ? "p-2 text-lg text-main-red absolute right-1 top-0"
            : "p-2 text-lg text-back-dGray absolute right-1 top-0 "
        }
      >
        <LuSearch />
      </button>
    </div>
  );
}

export default SearchInput;
