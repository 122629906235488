// hooks
import { useState } from "react";
import { useNavigate } from "react-router";
// images
import vertical_logo from "assets/logo/vertical_logo.png";
// icons
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const LandingFooter = ({ isFordAbsolute = true }) => {
  const navigate = useNavigate();
  const [isFold, setIsFold] = useState(false);
  return (
    <div
      className={`${
        isFordAbsolute && "absolute"
      } z-10 w-full select-none bottom-3`}
    >
      <div className="flex justify-between max-w-[1144px] m-auto">
        <img
          alt="vertical_logo"
          src={vertical_logo}
          className="w-[127px]"
          onClick={() => navigate("/")}
        />
        <div className="flex items-center text-xs font-bold text-text-dGray">
          {/* <span className="mx-2">제휴문의</span> */}
          <a
            className="mx-2"
            target="_blank"
            href="https://www.notion.so/29a93af98895492f8e0af775d60c7a38"
            rel="noreferrer"
          >
            이용약관
          </a>
          <a
            className="mx-2"
            target="_blank"
            href="https://www.notion.so/04f01263547f442e87f62a239449f8fb"
            rel="noreferrer"
          >
            개인정보 처리방침
          </a>
          {isFold ? (
            <IoIosArrowUp
              className="w-4 h-4"
              onClick={() => setIsFold(!isFold)}
            />
          ) : (
            <IoIosArrowDown
              className="w-4 h-4"
              onClick={() => setIsFold(!isFold)}
            />
          )}
        </div>
      </div>
      {isFold && (
        <div className="max-w-[1144px] pt-2 pb-4 m-auto mt-4 text-xs border-t text-gray-dGray border-gray-lGray ">
          <div className="my-2">
            <span className="mr-4">스튜디오라일라</span>
            <span>대표이사 : 박진강</span>
          </div>
          <div className="my-2">
            <span className="mr-4">사업자 등록번호: 198-28-01746</span>
            <span>통신판매업신고번호 : 제2024-서울강남-04635호</span>
          </div>

          <div className="my-2">
            <span>고객센터 : plumbyte.ai@gmail.com / 070-4577-7942</span>
            <span className="ml-2">
              주소 : 서울시 강남구 도곡로 84길 6, 15-25호
            </span>
          </div>
          <div className="my-4">
            <span>Copyright 2024 StudioLayla All right reserved</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingFooter;
