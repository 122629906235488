import React, { useState, useEffect } from "react";
import axios from "api/axios";
import { FaSearch } from "react-icons/fa";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { useSearchParams } from "react-router-dom";
import { FaSquarePlus } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegCopy } from "react-icons/fa";

const KeywordSearch = ({
    relKeyword,
    monthlyMobileQcCnt,
    monthlyAveMobileClkCnt,
    compIdx,
}) => {
    const [relKeywordData, setRelKeywordData] = useState(null);
    const [searchData, setSearchdData] = useState(null);
    const test = "dkdk";
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [selectedData, setSelectedData] = useState([]);

    function searchTest() {
        axios
            .post("https://dev.plumbyte-ai.com/api/related-keywords", {
                hintKeywords: searchData,
                scenario: 1,
            })
            .then((res) => {
                // console.log(res.data);
                setRelKeywordData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            searchTest();
        }
    };

    const sortedData = React.useMemo(() => {
        if (!relKeywordData) return [];
        let sortableItems = [...relKeywordData.keywordList];

        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
        //relKeywordData;
    }, [relKeywordData, sortConfig]);

    const requestSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    // 안유진 연계 코드⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇(헤더에 실시간 검색어 누르면 바로 여기서 요청 보내야 해서요! 코드좀 삽입할게요)
    const [searchParams] = useSearchParams();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const subject = searchParams.get("subject");
    useEffect(() => {
        if (subject) {
            setSearchdData(subject);
            setIsInitialRender(true);
        } else return;
    }, [subject]);
    useEffect(() => {
        if (isInitialRender && searchData && subject) {
            searchTest();
            setIsInitialRender(false);
        } else return;
    }, [searchData, subject, isInitialRender]);
    // 안유진 연계 코드⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆ 이 주석 사이는 건들지 말아주세요!!

    function selectKeywordData(keyword) {
        if (!selectedData.includes(keyword)) {
            setSelectedData([...selectedData, keyword]);
        }
        console.log(selectedData, keyword);
    }
    // 전체복사
    const copyAllKeywords = () => {
        const keywords = selectedData.join(", ");
        navigator.clipboard.writeText(keywords);
    };

    // 전체삭제
    const deleteAllKeywords = () => {
        setSelectedData([]);
    };

    // 개별삭제
    const deleteKeyword = (keyword) => {
        setSelectedData(selectedData.filter((item) => item !== keyword));
    };

    return (
        <div className="flex flex-col p-4 min-h-[750px] bg-white rounded-2xl">
            <div className="text-base text-gray-dGray font-semibold mb-4">
                연관 키워드 검색
            </div>
            <div className="flex items-center w-full overflow-hidden border border-gray-gray rounded-lg mb-2">
                <input
                    type="text"
                    className="w-full p-2 text-base focus:outline-none"
                    placeholder="키워드를 입력하세요"
                    value={searchData}
                    onChange={(e) => setSearchdData(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button
                    onClick={searchTest}
                    className="px-3 text-[18px] text-gray-gray hover:text-gray-dGray"
                >
                    <FaSearch />
                </button>
            </div>
            <div className="w-full max-w-4xl text-xs flex flex-col justify-between ">
                <table className="min-w-full bg-white rounded-lg z-0">
                    <thead>
                        <tr className="">
                            <th className="px-[2px] w-[145px]">
                                <div className="w-[145px] h-[35px] py-[6px] bg-gray-sGray rounded-lg flex items-center justify-center">키워드</div>
                            </th>
                            <th className="px-[2px] w-[145px]">
                                <div className="flex items-center w-[145px] h-[35px] justify-between px-2 py-[5px] rounded-lg bg-gray-sGray text-xs">
                                    <span className="mr-2">모바일 검색량</span>
                                    <div className="flex flex-col ml-2">
                                        <div className="relative group">
                                            <TiArrowSortedUp
                                                onClick={() => requestSort("monthlyMobileQcCnt")}
                                                className="cursor-pointer hover:text-status-red"
                                            />
                                            <div className="absolute bottom-full mb-[13px] hidden group-hover:block w-48 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-89px]">
                                                높은순/낮은순으로 정렬 가능
                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                            </div>
                                        </div>
                                        <div className="relative group mt-[-5px]">
                                            <TiArrowSortedDown
                                                onClick={() => requestSort("monthlyMobileQcCnt")}
                                                className="cursor-pointer hover:text-status-red"
                                            />
                                            <div className="absolute bottom-full mb-[20px] hidden group-hover:block w-48 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-89px]">
                                                높은순/낮은순으로 정렬 가능
                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th className="px-[2px] w-[145px]">
                                <div className="flex items-center justify-center py-[6px] w-[145px] h-[35px] bg-gray-sGray rounded-lg">
                                    모바일 클릭률
                                </div>
                            </th>
                            <th className="px-[2px] w-[145px]">
                                <div className="flex items-center justify-between px-2 py-[5px] w-[145px] h-[35px] rounded-lg bg-gray-sGray">
                                    <span className="mr-2">경쟁률</span>
                                    <div className="flex flex-col ml-2">
                                        <div className="relative group">
                                            <TiArrowSortedUp
                                                onClick={() => requestSort("compIdx")}
                                                className="cursor-pointer hover:text-status-red"
                                            />
                                            <div className="absolute bottom-full mb-[13px] hidden group-hover:block w-48 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-89px]">
                                                높은순/낮은순으로 정렬 가능
                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                            </div>
                                        </div>
                                        <div className="relative group mt-[-5px]">
                                            <TiArrowSortedDown
                                                onClick={() => requestSort("compIdx")}
                                                className="cursor-pointer hover:text-status-red"
                                            />
                                            <div className="absolute bottom-full mb-[20px] hidden group-hover:block w-48 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-89px]">
                                                높은순/낮은순으로 정렬 가능
                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item, index) => (
                            <tr key={index}>
                                <td className="flex items-center px-4 min-h-12 border-b">
                                    <FaSquarePlus
                                        onClick={() => selectKeywordData(item.relKeyword)}
                                        className="text-gray-gray w-[14px] h-[14px] mr-2 cursor-pointer"
                                    />
                                    <div className="line-clamp-2 max-w-[85px]">
                                        {item.relKeyword}
                                    </div>
                                </td>
                                <td className="px-4 h-12 border-b text-center">
                                    {item.monthlyPcQcCnt}
                                </td>
                                <td className="px-4 h-12 border-b text-center">
                                    {item.monthlyMobileQcCnt}
                                </td>
                                <td className="px-4 h-12 border-b text-center">
                                    {item.compIdx}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    {selectedData?.length > 0 && (
                        <div className="border border-gray-sGray rounded-lg relative mt-4">
                            <div className="h-[90px] overflow-y-scroll relative pr-[34px] flex items-start flex-wrap p-2 w-auto">
                                {selectedData.map((keyword, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-sGray rounded-md px-4 hover:px-2 mb-1 mr-2 py-1 group hover:text-main-red flex items-center justify-between hover:bg-[#F3395610]"
                                    >
                                        <div>{keyword}</div>
                                        <RiDeleteBin6Line
                                            onClick={() => deleteKeyword(keyword)}
                                            className="cursor-pointer text-main-red ml-[2px] hidden group-hover:block"
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="absolute top-4 right-4 flex space-x-2 ml-2">
                                <div className="relative group">
                                    <FaRegCopy
                                        onClick={copyAllKeywords}
                                        className="cursor-pointer text-gray-gray"
                                    />
                                    <div className="absolute bottom-0 mb-7 hidden group-hover:block w-40 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-75px]">
                                        추가한 전체 키워드 복사
                                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                    </div>
                                </div>
                                <div className="relative group">
                                    <RiDeleteBin6Line
                                        onClick={deleteAllKeywords}
                                        className="cursor-pointer text-gray-gray"
                                    />
                                    <div className="absolute bottom-0 mb-7 hidden group-hover:block w-40 bg-back-dGray text-white text-xs rounded py-1 px-2 text-center z-50 ml-[-76px]">
                                        추가한 전체 키워드 삭제
                                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full border-8 border-transparent border-t-back-dGray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default KeywordSearch;
