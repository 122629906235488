import React, { useState, useEffect } from "react";
import SettingInput from "components/Inputs/SettingInput";
import MypageCard from "../components/MypageCard";
import { useRequestGet, useRequestPatch } from "hooks/useRequest";
import DefaultBtnSm from "components/Buttons/DefaultBtn";
import BasicModal from "components/Modals/BasicModal"; // BasicModal import
import DeletionModalContent from "../components/DeletionModal"; // DeletionModalContent import
import { toast } from "react-toastify"; // Toastify import
import withdrawFunc from "pages/MyPage/components/withdrawFunc";
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import

// redux - 240910유진 추가 코드 : 헤더에 유저 정보 수정후 렌더링 반영을 위해
import { useDispatch } from "react-redux";
import { setIsRefetchPlum } from "../../../redux/isRefetchPlum";
/////////////////////////////////////////
const Information = () => {
  const dispatch = useDispatch();

  const getUserInfo = useRequestGet("/api/user", "userInfo");
  const patchUserInfo = useRequestPatch();

  const [nickname, setNickname] = useState("");

  useEffect(() => {
    if (getUserInfo?.data?.data) {
      setNickname(getUserInfo.data.data.name || "");
    }
  }, [getUserInfo?.data?.data]);

  const handleNicknameChange = (e) => setNickname(e.target.value);

  const onSubmit = async () => {
    const data = {
      name: nickname,
    };

    try {
      patchUserInfo.mutate({
        requestUrl: "/api/user/update",
        patchData: data,
      });

      // 성공 토스트 메시지 표시
      toast.success("설정 완료됐습니다!");
    } catch (error) {
      console.error("Error updating user info:", error);

      // 실패 토스트 메시지 표시
      toast.error("닉네임 업데이트에 실패했습니다.");
    }
  };

  // redux 20240910 유진 추가 코드 : 헤더에 유저 정보 수정후 렌더링 반영을 위해
  useEffect(() => {
    if (patchUserInfo?.status === "success") dispatch(setIsRefetchPlum(true));
  }, [patchUserInfo?.status]);
  //

  const handleDeleteSubmit = (reason) => {
    toast.success("탈퇴 문의가 접수되었습니다.");
    // 탈퇴 사유를 서버에 전송하는 로직을 추가합니다.
    if (getUserInfo.data.data)
      withdrawFunc(
        getUserInfo.data.data.userId,
        getUserInfo.data.data.name,
        reason
      );
  };

  return (
    <div className="mt-5 w-[780px] m-auto">
      <MypageCard
        subTitle={"가입 이메일"}
        title={getUserInfo?.data?.data.userId}
      />

      <div className="flex justify-between mt-5">
        <div className="w-full max-w-full pr-2">
          <p className="mb-2 font-semibold">닉네임</p>
          <div className="flex gap-2">
            <SettingInput
              type="text"
              value={nickname}
              setValue={setNickname}
              placeholder="닉네임을 입력하세요"
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <p className="mb-2 font-semibold">계정탈퇴</p>
        <div className="flex items-center gap-2">
          <p className="text-sm text-text-gray">
            계정을 탈퇴하시면 모든 프로젝트가 삭제되어 복구할 수 없습니다.
          </p>
          {/* 탈퇴 문의 모달 버튼 */}
          <BasicModal
            backgroundStyle="gradient"
            title="탈퇴 문의"
            content={<DeletionModalContent onSubmit={handleDeleteSubmit} />}
            modalBtn={<DefaultBtnSm content="탈퇴문의" />}
            activeBtnText={false}
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <button
          onClick={onSubmit}
          className="bg-gradient-to-bl from-main-red to-main-purple to-55% text-white py-3 px-6 rounded-xl text-base"
        >
          설정 완료
        </button>
      </div>
    </div>
  );
};

export default Information;
