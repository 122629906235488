import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateUtils";
import { getFeedbackTypeText } from "utils/FeedbackTypeUtils";
const ContentsList = ({
  seq,
  feedbackType,
  title,
  userName,
  createdAt,
  count,
  commentCount,
}) => {
  return (
    <Grid container spacing={1} className="border-b text-xs py-3">
      <Grid xs={0.6} className="text-center">
        <div>{seq}</div>
      </Grid>
      <Grid xs={2} className="text-center">
        <div>{getFeedbackTypeText(feedbackType)}</div>
      </Grid>
      <Grid xs={5.8}>
        <Link to={`/feedBack/${seq}`} className="ml-2">
          {title} <span className="text-main-red">[{commentCount}]</span>
        </Link>
      </Grid>
      <Grid xs={1.2} className="text-center">
        <div>{userName}</div>
      </Grid>
      <Grid xs={1.2} className="text-center">
        <div>{formatDate(createdAt)}</div>
      </Grid>
      <Grid xs={1.2} className="text-center">
        <div>{count}</div>
      </Grid>
    </Grid>
  );
};

export default ContentsList;
