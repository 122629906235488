import React, { useState } from "react";
import { getFeedbackTypeText } from "utils/FeedbackTypeUtils";
import { formatDate } from "utils/DateUtils";
import EditBtn from "components/Buttons/EditBtn";
import DeleteBtn from "components/Buttons/DeleteBtn";
import { useNavigate } from "react-router-dom";
import { useRequestGet } from "hooks/useRequest";
import DeleteModal from "components/Modals/DeleteModal";

const Content = ({
  feedbackType,
  title,
  userName,
  createdAt,
  count,
  feedbackHtml,
  feedseq,
  handleDelete,
  userSeq,
}) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pendingDeleteId, setPendingDeleteId] = useState(null);

  const handleEditClick = () => {
    navigate(`/feedback/edit/${feedseq}`);
  };

  let getUserInfo = useRequestGet("/api/user", "userInfo");

  const handleDeleteClick = () => {
    setPendingDeleteId(feedseq);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (pendingDeleteId) {
      handleDelete(pendingDeleteId);
      setPendingDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setPendingDeleteId(null);
  };

  return (
    <div>
      <div className="flex justify-between bg-gray-sGray py-3 px-4 rounded-lg">
        <div>
          <div className="text-xs">{getFeedbackTypeText(feedbackType)}</div>
          <div>{title}</div>
        </div>
        <div className="flex gap-2 text-gray-gray items-center text-sm">
          <p>{userName}</p>
          <p>{formatDate(createdAt)}</p>
          <p>조회수 {count}</p>
          {userSeq === getUserInfo?.data?.data?.seq ? (
            <div className="flex gap-1 ml-2">
              <EditBtn editFunc={handleEditClick} />
              <DeleteBtn deletFunc={handleDeleteClick} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="min-h-72 border-b">
        <div className="p-6 text-text-dGray text-sm">{feedbackHtml}</div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="문의사항을 삭제하시겠습니까?"
        />
      )}
    </div>
  );
};

export default Content;
