import React from "react";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";

function CategoryDropdown({ category, setCategory, background, itemList }) {
  // const itemList = [
  //   { label: "전체", value: "ALL" },
  //   { label: "서비스제안", value: "SERVICE_SUGGESTION" },
  //   { label: "오류접수", value: "BUG_REPORT" },
  //   { label: "자유의견", value: "FREE_OPINION" },
  // ];

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <Box sx={{ width: "100%", background: background, borderRadius: "5px" }}>
      <FormControl fullWidth>
        <Select
          sx={{
            fontSize: "12px",
            color: "#333333 !important",
            background: background,
            borderRadius: "10px",
            width: "100%",
            "& .MuiSelect-select": {
              padding: 1.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-select>svg": {
              display: "none",
            },
            ".MuiSelect-icon": {
              color: "#333333",
              right: "10px",
            },
          }}
          value={category}
          IconComponent={IoIosArrowDown}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            PaperProps: {
              sx: {
                height: "12rem",
                borderRadius: "15px",
                margin: "10px",
                paddingX: "6px",
              },
            },
          }}
        >
          <MenuItem
            value=""
            disabled
            sx={{
              display: "none",
              fontSize: "13px",
            }}
          >
            카테고리
          </MenuItem>

          {itemList.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={
                item.value === category
                  ? {
                      fontSize: "13px",
                      borderRadius: "10px",
                      color: "#000000",
                      background: "#F1F3F5 !important",
                      marginY: "3px",
                    }
                  : {
                      fontSize: "13px",
                      borderRadius: "10px",
                      color: "#858e96",
                      background: "#ffffff !important",
                      "&:hover": {
                        background: "#F1F3F5 !important",
                      },
                      marginY: "3px",
                    }
              }
            >
              {item.label}
              {item.value === category && (
                <FaCheck className="text-main-pink ml-2" />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default CategoryDropdown;
