import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isNavOpen: true, // 초기 상태 설정
};

const isNavOpenSlice = createSlice({
    name: 'navOpen',
    initialState,
    reducers: {
        setIsNavOpen: (state, action) => {
            // 상태 업데이트
            state.isNavOpen = action.payload;
        },
        toggleIsNavOpen: (state) => {
            // 상태 토글
            state.isNavOpen = !state.isNavOpen;
        },
    },
});

export const { setIsNavOpen, toggleIsNavOpen } = isNavOpenSlice.actions;

export default isNavOpenSlice.reducer;
