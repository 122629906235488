// hooks
import { useState, useMemo, useRef, useEffect } from "react";
import { useRequestGet, useRequestPatch } from "hooks/useRequest";
import { useSearchParams, useLocation } from "react-router-dom";
// Common Components

import BasicTooltip from "components/ToolTips/BasicToolTips";
import EditorNavigateHeader from "layout/EditorNavigateHeader";
import { toast } from "react-toastify"; // Toastify import

// Behance Components
import ReCreateNav from "pages/BehanceEditor/components/ReCreateNav";
import CreateMediaCard from "pages/BehanceEditor/components/CreateMediaCard";
import TextMediaCard from "pages/BehanceEditor/components/TextMediaCard";
import ImageMediaCard from "pages/BehanceEditor/components/ImageMediaCard";
import RecommandMediaCard from "pages/BehanceEditor/components/RecommandMediaCard";
import PopoverMenu from "pages/BehanceEditor/components/PopoverMenu";
// icons
import { FaRegCopy } from "react-icons/fa";
// redux
import { useSelector, useDispatch } from "react-redux";
import { clearReCreateAIText } from "../../redux/aiTextSlice";
import { clearReCreateAIImage } from "../../redux/aiImageSlice";

const BehanceEditor = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const locationURL = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const projectSeq = searchParams.get("projectSeq");
  const contentSeq = searchParams.get("contentSeq");
  const [data, setData] = useState([]);
  const [AIModalTab, setAIModalTab] = useState("AIText");
  const [curFocusIdx, setCurFocusCreatedIdx] = useState(0);

  const [contentsDragoverAble, setContentsDragoverAble] = useState(true); // 드래그 오버 방지
  const [outSideClicked, setOutSideClicked] = useState(false);

  // redux로 상태 초기화
  const reCreateAIText = useSelector((state) => state.text.reCreateAIText);
  const reCreateAIImage = useSelector((state) => state.image.reCreateAIImage);
  const handleReCreateAITextSave = () => {
    dispatch(clearReCreateAIText());
  };
  const handleReCreateAIImageSave = () => {
    dispatch(clearReCreateAIImage());
  };
  useEffect(() => {
    handleReCreateAITextSave();
    handleReCreateAIImageSave();
  }, []);

  // select-none
  const handleCreateMedia = (type, idx, contents) => {
    setCurFocusCreatedIdx(idx);
    const newData = [...data];
    const sampleData = {
      type: type,
      contents: contents ? contents : "",
    };
    const beforeData = newData.slice(0, idx);
    const nextData = newData.slice(idx);
    setData([...beforeData, sampleData, ...nextData]);
  };
  const handleReplaceMedia = (type, idx, contents) => {
    setCurFocusCreatedIdx(idx);
    const newData = [...data];
    const updatedData = {
      type: type,
      contents: contents ? contents : "",
    };
    newData.splice(idx, 1, updatedData);
    setData(newData);
  };

  // init => 처음 생성된 경우
  const [autoSaveImages, setAutoSaveImages] = useState([]); // 자동저장 이미지

  const [firstVariable, setFirstVariable] = useState("");
  const [secondVariable, setSecondVariable] = useState("");
  const [thirdVariable, setThirdVariable] = useState("");
  const [fourthVariable, setFourthVariable] = useState("");
  const [fifthVariable, setFifthVariable] = useState("");
  const [brandSeq, setBrandSeq] = useState("");
  const [targetSeq, setTargetSeq] = useState("");
  const [templateCode, setTemplateCode] = useState("");
  const [toneCode, setToneCode] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [objectiveCode, setObjectiveCode] = useState("");
  const [model, setModel] = useState("claude");

  const getContentDetail = useRequestGet(
    `/api/project/${projectSeq}/content/${contentSeq}`,
    `getContentDetail-${projectSeq}-${contentSeq}`
  );
  useEffect(() => {
    if (getContentDetail.status === "success" && getContentDetail?.data?.data) {
      // get 요청으로 기존생성시 넘어오는 경우
      const {
        template,
        contentHtml,
        firstVariable,
        secondVariable,
        thirdVariable,
        fourthVariable,
        fifthVariable,
        brand,
        target,
        tone,
        language,
        objective,
        model,
      } = getContentDetail?.data?.data;
      // console.log('initLanguage', getContentDetail?.data?.data);

      const parsedData = contentHtml ? JSON.parse(contentHtml) : [];
      setData(parsedData);
      // handleMarkdown(parsedData);
      if (template) setTemplateCode(template);
      if (brand) setBrandSeq(brand);
      if (target) setTargetSeq(target);
      if (tone) setToneCode(tone);
      if (language) setLanguageCode(language);
      if (objective) setObjectiveCode(objective);
      if (model) setModel(model);
      if (secondVariable) setSecondVariable(secondVariable);
      if (thirdVariable) setThirdVariable(thirdVariable);
      if (fourthVariable) setFourthVariable(fourthVariable);
      if (fifthVariable) setFifthVariable(fifthVariable);
      if (firstVariable) setFirstVariable(firstVariable); // 일부러 autosave 때문에 firstVariable이 맨 아래위치
    }
  }, [getContentDetail.status, getContentDetail?.data?.data]);

  /***************** 콘텐츠 렌더링 *****************/
  const getProcessedContent = useMemo(() => {
    return data?.map((el, idx) => {
      switch (el.type) {
        case "text":
          return (
            <TextMediaCard
              key={`text-${idx}`}
              idx={idx}
              isFocus={idx === curFocusIdx}
              setCurFocusCreatedIdx={setCurFocusCreatedIdx}
              data={data}
              setData={setData}
              contents={el?.contents}
            />
          );
        case "image":
          return (
            <ImageMediaCard
              key={`image-${idx}`}
              idx={idx}
              setCurFocusCreatedIdx={setCurFocusCreatedIdx}
              data={data}
              setData={setData}
              imageSrc={el.contents}
            />
          );
        case "recommand":
          return (
            <RecommandMediaCard
              key={`recommand-${idx}`}
              idx={idx}
              data={data}
              setData={setData}
              contents={el?.contents}
              handleReplaceMedia={handleReplaceMedia}
              AIModalTab={"AIImage"}
              setAIModalTab={setAIModalTab}
            />
          );
        default:
          return null;
      }
    });
  }, [data, curFocusIdx]);

  /***************** 빈 콘텐츠 정리하기 *****************/
  const handleOutSideClick = () => {
    // 외부 클릭 처리 로직
    setCurFocusCreatedIdx(null);
    setOutSideClicked(true);
    handleEmptyContents();
  };
  useEffect(() => {
    handleEmptyContents();
  }, [outSideClicked]);

  const handleEmptyContents = () => {
    const tempAutoSaveImages = [];
    if (outSideClicked) {
      setOutSideClicked(false);
      const rawData = [...data];
      rawData.map((el, idx) => {
        if (el.type === "text") {
          const element =
            ref.current.children[idx].children[0].children[0].children[1]
              .children[0]; // 내부 텍스트에 접근
          rawData[idx].contents = element.innerHTML;
        }
      });

      // 새로운 데이터 배열을 생성
      const newData = rawData
        .map((el, idx) => {
          if (el.type === "text") {
            const element = ref.current.children[idx].children[0];
            // 텍스트 요소의 내용이 비어 있지 않으면 요소를 유지
            return element.textContent.trim() !== "" ? el : null;
          } else if (el.type === "image") {
            tempAutoSaveImages.push(el.contents);
            // 이미지 요소는 항상 유지
            return el;
          } else if (el.type === "recommand") {
            // 추천 요소는 항상 유지
            return el;
          }
          return null;
        })
        // null 값을 필터링하여 제거
        .filter((el) => el !== null);

      // 업데이트된 데이터를 상태로 설정
      setAutoSaveImages(tempAutoSaveImages);
      setData(newData);
    }
  };
  /***************** 콘텐츠 복사하기 *****************/
  const handleCopy = async () => {
    const processedData = data
      .map((el, idx) => {
        switch (el.type) {
          case "text":
            return `<div>${el.contents}</div>`;
          case "image":
            return `<div><img src="${el.contents}" alt="${el.idx}-Image" /></div>`;

          default:
            return;
        }
      })
      .join("");

    const htmlContent = ` <div>${processedData}</div>`;
    try {
      const blob = new Blob([htmlContent], { type: "text/html" });
      const clipboardItem = new ClipboardItem({ "text/html": blob });
      await navigator.clipboard.write([clipboardItem]);
      toast.success("클립보드에 복사되었습니다.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("복사 실패:", navigator.clipboard.writeText(htmlContent));
    }
  };
  /***************** 콘텐츠 저장하기 *****************/
  const [timeLeft, setTimeLeft] = useState(900); // 15분 = 900초
  // 얘는 자동저장이 필요 없잖아?

  useEffect(() => {
    // 타이머가 0이 되면 자동 저장 함수 호출
    if (timeLeft === 0) {
      handleAutoSave();
      setTimeLeft(900); // 타이머를 다시 15분으로 리셋
    }

    // 1초마다 타이머 감소
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000); // 1000 밀리초 = 1초

    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const patchSave = useRequestPatch();

  const [autoSaveTrigger, setAutoSaveTrigger] = useState(false);
  const handleAutoSave = () => {
    // 자동 저장 요청을 보내는 함수
    toast.success("자동저장 되었습니다.", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    handleOutSideClick(); // data를 한번 저장
    setAutoSaveTrigger(true);
  };
  useEffect(() => {
    if (autoSaveTrigger && outSideClicked) {
      // console.log(1)
      setAutoSaveTrigger(false);

      const patchBody = {
        contentHtml: JSON.stringify(data),
        projectSeq: projectSeq,
        contentSeq: contentSeq,
        ...(autoSaveImages.length > 0 && { s3Urls: [...autoSaveImages] }),

        ...(model && { model: model }),
        ...(firstVariable && { firstVariable: firstVariable }),
        ...(secondVariable && { secondVariable: secondVariable }),
        ...(thirdVariable && { thirdVariable: thirdVariable }),
        ...(fourthVariable && { fourthVariable: fourthVariable }),
        ...(fifthVariable && { fifthVariable: fifthVariable }),
        ...(brandSeq && { brandSeq: brandSeq.seq }),
        ...(targetSeq && { targetSeq: targetSeq.seq }),
        ...(templateCode && {
          templateCode: templateCode.templateCode,
        }),
        ...(toneCode && { toneCode: toneCode.toneCode }),
        ...(objectiveCode && { objectiveCode: objectiveCode.objectiveCode }),
        languageCode: languageCode.languageCode || "KR",
      };
      // console.log(patchBody);

      patchSave.mutate({
        requestUrl: `/api/project/${projectSeq}/content/${contentSeq}`,
        patchData: patchBody,
      });
      setTimeLeft(900);
    }
  }, [autoSaveTrigger, outSideClicked]);
  useEffect(() => {
    if (patchSave.status === "success") {
      getContentDetail.refetch();
    }
  }, [patchSave.status]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  /***************** 재생성시 refetch 함수가 호출된 경우 *****************/
  return (
    <div className="wrapper" onClick={handleOutSideClick}>
      {getContentDetail?.data?.data && (
        <ReCreateNav
          setData={setData}
          refetchFunc={getContentDetail.refetch}
          templateCode={templateCode.templateCode}
          firstVariable={firstVariable}
          secondVariable={secondVariable}
          thirdVariable={thirdVariable}
          fourthVariable={fourthVariable}
          fifthVariable={fifthVariable}
          brand={brandSeq.seq}
          target={targetSeq.seq}
          tone={toneCode.toneCode}
          language={languageCode.languageCode || "KR"}
          objective={objectiveCode.objectiveCode}
          model={model}
        />
      )}

      <div className="cherry-wrapper">
        <div className="mt-1"></div>
        <EditorNavigateHeader />
        <div className="h-full p-8 mt-4 mb-12 bg-white rounded-t-xl">
          <div className="flex items-center justify-between w-full">
            <div onClick={handleCopy}>
              <div className="flex items-center">
                <BasicTooltip
                  title="Copy"
                  placement="top"
                  contents={
                    <FaRegCopy className="w-4 h-4 mr-2 text-gray-dGray" />
                  }
                />
                {/* 복사하기 옆에 제목을 추가하는 경우가 되겠다. */}
                {16 <= getContentDetail?.data?.data?.template?.seq &&
                  getContentDetail?.data?.data?.template?.seq <= 162 &&
                  fourthVariable && (
                    <div className="text-lg font-bold">{fourthVariable} </div>
                  )}
              </div>
            </div>
            <div className="flex items-center select-none w-[210px] ml-2">
              <div className="text-xs text-gray-dGray">
                자동 저장 완료 {formatTime(timeLeft)}
              </div>

              <button
                onClick={handleAutoSave}
                className="px-3 py-1 ml-2 text-sm border rounded-[10px] border-gray-gray"
              >
                임시 저장
              </button>
            </div>
          </div>
          <PopoverMenu setContentsDragoverAble={setContentsDragoverAble} />
          <CreateMediaCard
            idx={0}
            data={data}
            setData={setData}
            model={model}
            handleCreateMedia={handleCreateMedia}
            AIModalTab={AIModalTab}
            setAIModalTab={setAIModalTab}
          />
          {data && data?.length > 0 && (
            <div className="z-10" ref={ref}>
              {getProcessedContent.map((el, idx) => (
                <div key={`getProcessedContent-${idx}`}>
                  <div>{el}</div>
                  <CreateMediaCard
                    idx={idx + 1}
                    data={data}
                    setData={setData}
                    model={model}
                    handleCreateMedia={handleCreateMedia}
                    AIModalTab={AIModalTab}
                    setAIModalTab={setAIModalTab}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BehanceEditor;
