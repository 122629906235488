import { useEffect, useState } from "react";
import { useRequestPost } from "hooks/useRequest";
// commons
import TextArea from "components/TextArea";
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
// icons
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
// images
import loadingImg from "assets/images/loadingImg.gif";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
// redux
import { useDispatch } from "react-redux";
import { setIsRefetchPlum } from "../../../../redux/isRefetchPlum";
const ReWriteModal = ({
  textContent,
  modalBtn,
  isShow,
  setIsShow,
  textContentReWrite,
}) => {
  const [essentialText, setEssentialText] = useState("");
  const [multiply, setMultiply] = useState(0);
  const postReWrite = useRequestPost();
  const dispatch = useDispatch();

  const handlePost = () => {
    // C05
    if (multiply !== 0) {
      const data = {
        model: "claude",
        templateCode: "C05",
        // firstVariable: aiModalInput, 문단 목표
        secondVariable: Math.round(textContent.length * multiply), //문단길이
        fourthVariable: essentialText, // 재 작성할 문단에 반드시 포함되어야 하는 내용
        thirdVariable: textContent, // 재작성할 문단
        // tone
        // language
      };
      postReWrite.mutate({
        requestUrl: "/api/chat-completion/run",
        postData: data,
      });
    } else {
      toast.error("글자수를 선택하세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    if (postReWrite.status === "success") {
      const { value } = postReWrite?.data?.data;
      textContentReWrite(value);
      setIsShow(false);
      dispatch(setIsRefetchPlum(true));
    }
  }, [postReWrite.status]);
  return (
    <div>
      {postReWrite.isPending && (
        <div className="w-full top-0 left-0 h-screen bg-[#00000040] z-40 fixed flex justify-center items-center">
          <div className="relative">
            <img src={loadingImg} alt="loading" className="w-48 h-48" />
          </div>
        </div>
      )}
      <div>{modalBtn}</div>
      {isShow && (
        <div className="w-full h-full">
          <div
            className="fixed z-30 w-full h-full bg-[#00000050] left-0 top-0 flex justify-center items-center"
            onClick={(e) => {
              e.stopPropagation();
              setIsShow(false);
            }}
          >
            <div
              className="w-full text-sm max-w-[24rem] bg-white rounded-lg p-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between mb-3 text-base font-bold">
                <span>다시쓰기</span>
                <IoClose onClick={() => setIsShow(false)} />
              </div>

              <div className="flex justify-between mt-2 text-sm font-bold">
                <div>
                  <span className="text-main-pink">*</span>
                  글자수 입력
                </div>
                <div className="text-sm font-normal">
                  현재 글자수
                  <span className="text-main-pink">{textContent.length}</span>
                </div>
              </div>

              <input
                type="number"
                value={multiply ? Math.round(textContent.length * multiply) : 0}
                className="w-full p-2 my-2 rounded-md focus:outline-none bg-gray-sGray"
              />
              <div className="text-gray-dGray">
                글자 수는 대략적인 값입니다.
              </div>

              <div className="flex mt-2 mb-4 text-sm">
                <button
                  onClick={() => setMultiply(0.5)}
                  className={
                    multiply === 0.5
                      ? " flex items-center border mr-2 font-bold border-main-pink bg-white text-main-pink px-2 py-1 rounded-md"
                      : " flex items-center font-bold mr-2 bg-gray-sGray text-gray-dGray px-2 py-1 rounded-md"
                  }
                >
                  <FaCheck className="mr-1" />
                  1/2로
                </button>
                <button
                  onClick={() => setMultiply(2)}
                  className={
                    multiply === 2
                      ? " flex items-center border mr-2 font-bold border-main-pink bg-white text-main-pink px-2 py-1 rounded-md"
                      : "  flex items-center font-bold mr-2 bg-gray-sGray text-gray-dGray px-2 py-1 rounded-md"
                  }
                >
                  <FaCheck className="mr-1" />
                  2배로
                </button>
              </div>

              <div className="mb-1 text-sm font-bold">
                <span className="text-main-pink">*</span>
                반드시 포함할 내용
              </div>

              <TextArea
                id="text"
                type="text"
                placeholder="내용 입력"
                rows={8}
                value={essentialText}
                setValue={setEssentialText}
              />

              <div className="flex justify-center mt-2">
                <button
                  onClick={handlePost}
                  className="px-8 py-2 text-sm text-white rounded-[10px] bg-main-red"
                >
                  (-1Byte) 다시쓰기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReWriteModal;
