// type: default, success, fail, disabled
const TextArea = ({
  id,
  name,
  type = "default",
  value,
  setValue,
  rows,
  cols,
  maxLength,
  placeholder = "",
}) => {
  const defaultStyle =
    "bg-[#F8F9FA] focus:outline-1 focus:outline-main-pink w-full p-2 rounded-lg resize-none";
  const successStyle =
    "bg-text-white focus:outline-status-green border-2 border-status-green w-full p-2 rounded-lg resize-none";
  const failStyle =
    "bg-text-white focus:outline-status-red border-2 border-status-red w-full p-2 rounded-lg resize-none";
  const disabledStyle =
    "bg-gray-sGray text-text-gray w-full p-2 rounded-lg resize-none";
  const whiteStyle =
    "bg-text-white focus:outline-1 focus:outline-main-pink w-full p-2 rounded-lg resize-none";
  return (
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      rows={rows}
      cols={cols}
      maxLength={maxLength}
      disabled={type === "disabled"}
      className={
        type === "default"
          ? defaultStyle
          : type === "success"
          ? successStyle
          : type === "fail"
          ? failStyle
          : type === "disabled"
          ? disabledStyle
          : type === "white"
          ? whiteStyle
          : defaultStyle
      }
      value={value || ""} // Use value attribute instead of children
    />
  );
};

export default TextArea;
