import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid"; // UUID 라이브러리 import

import PaymentCard from "../components/PaymentCard";
import PaymentInfoBox from "../components/PaymentInfoBox";
import {
  useRequestPost,
  useRequestGet,
  useRequestDelete,
} from "hooks/useRequest";
import { useQueryClient } from "@tanstack/react-query";
import DeleteModal from "components/Modals/DeleteModal";
const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tossPayments, setTossPayments] = useState(null);
  const [cardRegistered, setCardRegistered] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [billingKey, setBillingKey] = useState(""); // 빌링 키 상태 추가
  const queryClient = useQueryClient();
  const postBillingKey = useRequestPost();
  const postPayment = useRequestPost();
  const cardInfo = useRequestGet("/api/user/billing-key", "getCardInfo");
  const authKey = searchParams.get("authKey");

  // localStorage에서 읽거나 location.state에서 데이터 가져오기
  const paymentInfo =
    JSON.parse(localStorage.getItem("paymentInfo")) || location.state || {};
  const {
    price,
    title,
    customerKey,
    username,
    checkList,
    byte,
    btnTitle,
    email,
    eng,
  } = paymentInfo;

  useEffect(() => {
    if (location.state) {
      localStorage.setItem("paymentInfo", JSON.stringify(location.state));
    }

    const clientKey = process.env.REACT_APP_CLIENT_KEY;

    if (!clientKey) {
      console.error("클라이언트 키가 정의되지 않았습니다.");
      return;
    }

    loadTossPayments(clientKey)
      .then((tossPaymentsInstance) => {
        setTossPayments(tossPaymentsInstance);
        if (authKey) {
          generateBillingKeyAuth();
        }
      })
      .catch((error) => {
        console.error("Tosspayments SDK 로드 실패", error);
        toast.error("결제 시스템을 초기화하는 중 오류가 발생했습니다.");
      });
  }, [authKey]);

  useEffect(() => {
    // 카드 정보가 로드된 후 빌링 키를 설정
    if (cardInfo.data && cardInfo.data.data && cardInfo.data.data.billingKey) {
      setBillingKey(cardInfo.data.data.billingKey); // 카드 정보에서 빌링 키 가져오기
    }
  }, [cardInfo]);

  const handleCardRegistration = async () => {
    if (!tossPayments) {
      toast.error("결제 시스템을 초기화 중입니다.");
      return;
    }

    try {
      await tossPayments.requestBillingAuth("CARD", {
        customerKey: customerKey,
        successUrl: window.location.origin + "/plan/payment",
        failUrl: window.location.origin + "/plan/payment/fail",
        customerEmail: email,
        customerName: username,
      });
    } catch (error) {
      console.error("카드 등록 실패", error);
      toast.error("카드 등록 중 오류가 발생했습니다.");
    }
  };

  const generateBillingKeyAuth = () => {
    const requestData = {
      authKey: authKey,
      customerKey: searchParams.get("customerKey"),
    };

    postBillingKey.mutate(
      {
        requestUrl: `/api/toss-payments/generate-billing-key-auth`,
        postData: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getCardInfo");
        },
      }
    );
  };

  // 빌링 키 상태 변경 감지
  useEffect(() => {
    if (postBillingKey.status === "success") {
      navigate("/plan/payment");
      toast.success("카드 등록 및 빌링 키 발급이 완료되었습니다.");
    } else if (postBillingKey.status === "error") {
      console.error("빌링 키 발급 요청 실패", postBillingKey.error);
      toast.error("결제 요청 중 문제가 발생했습니다.");
    }
  }, [
    postBillingKey.status,
    postBillingKey.data,
    postBillingKey.error,
    navigate,
  ]);

  const PaymentPost = async () => {
    if (!billingKey) {
      toast.error("빌링 키가 없습니다. 결제를 진행할 수 없습니다.");
      return;
    }

    const orderId = uuidv4(); // 고유한 주문 ID 생성

    const paymentData = {
      amount: price, // 실제 결제 금액 사용
      customerKey: customerKey,
      orderId: orderId, // 고유한 주문 ID 설정
      orderName: eng,
      cardInstallmentPlan: 0,
      customerEmail: email,
      customerMobilePhone: "",
      customerName: username,
      taxExemptionAmount: 0,
      taxFreeAmount: 0,
    };

    try {
      await postPayment.mutate({
        requestUrl: `/api/toss-payments/approve-automatic-payment/${billingKey}`,
        postData: paymentData,
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("결제 요청 중 오류 발생", error);
      toast.error("결제 요청 중 문제가 발생했습니다.");
    }
  };
  useEffect(() => {
    if (postPayment.status === "success") {
      toast.success("결제가 완료 되었습니다.");
      navigate("/plan/payment_success");
    } else if (postPayment.status === "error") {
      console.error("빌링 키 발급 요청 실패", postPayment.error);
      toast.error("결제 요청 중 문제가 발생했습니다.");
    }
  }, [postPayment.status, postPayment.data, postPayment.error, navigate]);

  const deleteFeedCommentMutation = useRequestDelete(["getCardInfo"]);

  const handleDelete = () => {
    deleteFeedCommentMutation.mutate(
      {
        url: `/api/user/billing-key/delete`,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getCardInfo");
          setIsDeleteModalOpen(false); // 삭제 성공 시 모달 닫기
        },
      }
    );
  };
  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true); // 삭제 모달 열기
  };

  const handleDeleteConfirm = () => {
    handleDelete(); // 삭제 확인 시 실제 삭제 실행
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // 모달 닫기
  };
  const calculateDates = () => {
    const today = new Date();
    const paymentDate = today.toISOString().split("T")[0];
    const nextPaymentDate = new Date(today.setMonth(today.getMonth() + 1))
      .toISOString()
      .split("T")[0];

    return { paymentDate, nextPaymentDate };
  };

  const { paymentDate, nextPaymentDate } = calculateDates();

  return (
    <div className="all-bg-color wrapper">
      <div className="cherry-wrapper">
        <h3 className="text-xl font-bold  mt-[5px]">요금제 결제</h3>
        <div className="flex gap-5 justify-center mt-5 ">
          <PaymentCard
            title={title}
            price={price}
            checkList={checkList || []}
            byte={byte}
            btnTitle={btnTitle}
            username={username}
          />
          <div className="flex flex-col max-w-3xl w-full">
            <PaymentInfoBox
              price={price}
              paymentDate={paymentDate}
              nextPaymentDate={nextPaymentDate}
              paymentMethod={"매 달 자동 결제"}
              onRegisterCard={handleCardRegistration}
              cardRegistered={cardRegistered}
              cardNumber={cardInfo?.data?.data?.cardNumber}
              postPayment={PaymentPost}
              handleDeleteClick={handleDeleteClick}
            />
            <div className="p-6 border border-gray-lGray text-text-gray mt-5 rounded-xl">
              <div className="mb-5">
                <h4 className="text-base mb-2">결제 유의사항</h4>
                <p className="text-sm">
                  결제일을 기준으로 매달 자동으로 겨제가 이루어지며, 해지 시
                  결제된 금액은 환불되지 않습니다. 다만 남은 <br /> 기간 동안
                  현재 요금제 혜택은 계속해서 이용 가능합니다. 월간 요금제의
                  이용 기간은 30일입니다.
                </p>
              </div>
              <div>
                <h4 className="text-base">환불 안내</h4>
                <p className="text-sm">
                  서비스 특성상 결제일 이후 자동 결제가 완료된 경우 환불이
                  불가하며,
                  <br />
                  미사용 건에 한해 7영업일 이내에 요청할 경우에만 환불이
                  가능합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="카드를 삭제하시겠습니까?"
        />
      )}
    </div>
  );
};

export default PaymentPage;
