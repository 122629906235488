// hooks
import { useState } from "react";
// icons
import { IoClose } from "react-icons/io5";

const BasicModal = ({
  title = "타이틀을입력하세요",
  content = <input type="text" />,
  modalBtn,
  activeBtnText = true,
  activeFunc,
  cancleBtn = false,
  backgroundStyle = "white",
}) => {
  const [isShow, setShow] = useState(false);
  const bgStyleMatcher = {
    white: "bg-[#ffffff]",
    gradient: "bg-gradient-modal",
    dark: "bg-[#000000]",
  };
  const handleShow = () => {
    setShow(!isShow);
  };

  const handleActiveFunc = () => {
    if (activeFunc) activeFunc();
    handleShow();
  };

  return (
    <div>
      <div className="" onClick={handleShow}>
        {modalBtn}
      </div>
      {isShow && (
        <div
          className="fixed w-full h-full bg-[rgba(0,0,0,0.5)] top-0 right-0 z-[30] flex justify-center items-center"
          onClick={handleShow}
        >
          <div
            className={
              backgroundStyle
                ? `${bgStyleMatcher[backgroundStyle]} w-fit p-4 rounded-lg`
                : `w-fit p-4 bg-text-white rounded-lg`
            }
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <div className="font-bold text-sm">{title}</div>
              <IoClose
                onClick={handleShow}
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <div>{content}</div>
            <div className="text-center">
              {activeBtnText && (
                <button
                  className="text-text-white bg-main-pink min-w-[150px] p-2 rounded-md"
                  onClick={handleActiveFunc}
                >
                  {activeBtnText}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BasicModal;
