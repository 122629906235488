import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reCreateAIImage: '', // 초기 상태 설정
};

const aiImageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setReCreateAIImage: (state, action) => {
            // 상태 업데이트
            state.reCreateAIImage = action.payload;
        },
        clearReCreateAIImage: (state) => {
            // 상태 초기화
            state.reCreateAIImage = '';
        },
    },
});

export const { setReCreateAIImage, clearReCreateAIImage } = aiImageSlice.actions;

export default aiImageSlice.reducer;
