import { useNavigate } from "react-router-dom";

const ContentCard = ({ title, tag, description }) => {
  const navigate = useNavigate();

  return (
    <button
      className="px-5 w-full py-4 border-2 rounded-lg bg-back-lGray"
      onClick={() => navigate("/templates")}
    >
      <div className="flex items-center">
        <span
          className={`${
            tag === "SNS"
              ? "text-main-red bg-[#F3395615]"
              : tag === "글쓰기"
              ? "text-main-purple bg-[#9C1AD420]"
              : tag === "마케팅"
              ? "text-text-dGray bg-[#acb5bd25]"
              : tag === "계획"
              ? "text-sub-skyBlue bg-[#28cce520]"
              : tag === "무료"
              ? "bg-text-white text-main-pink"
              : "bg-main-pink text-text-white"
          }  px-1  text-xs rounded-md`}
        >
          {tag}
        </span>
        <div className="ml-1 text-sm font-semibold">{title}</div>
      </div>
      <div
        className="text-start mt-2 text-[13px] text-[#787878] break-words"
        style={{ wordBreak: "keep-all" }}
      >
        {description}
      </div>
    </button>
  );
};

export default ContentCard;
