import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isRefetchPlum: false, // 초기 상태 설정
};

const isRefetchPlumSlice = createSlice({
    name: 'refetchPlum',
    initialState,
    reducers: {
        setIsRefetchPlum: (state, action) => {
            // 상태 업데이트
            state.isRefetchPlum = action.payload;
        },
    },
});

export const { setIsRefetchPlum } = isRefetchPlumSlice.actions;

export default isRefetchPlumSlice.reducer;
