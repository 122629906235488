import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
  useRequestPost,
  useRequestGet,
  useRequestPatch,
} from "hooks/useRequest";
import TargetForm from "./components/TargetForm";
import { getInterestCodeByLabel } from "utils/SettingValue";
import { toast } from "react-toastify"; // Toastify import
const Target = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [targetName, setTargetName] = useState("");
  const [targetAgeGroup, setTargetAgeGroup] = useState([15, 40]);
  const [targetInterestsCode, setTargetInterestsCode] = useState("");
  const [targetGender, setTargetGender] = useState("male");
  const [isEditMode, setIsEditMode] = useState(false);

  const postMutation = useRequestPost();
  const patchMutation = useRequestPatch();
  const targetData = useRequestGet(
    id ? `/api/target/${id}` : null,
    id ? `target-${id}` : null
  );

  useEffect(() => {
    if (id && targetData?.status === "success" && targetData?.data) {
      setIsEditMode(true);
      const {
        targetName,
        targetAgeGroup,
        targetInterestsNameKor,
        targetGender,
      } = targetData.data.data;
      setTargetName(targetName);
      setTargetAgeGroup([
        Number(targetAgeGroup.slice(0, 2)),
        Number(targetAgeGroup.slice(2)),
      ]);
      // 한글 이름을 영어 코드로 변환
      setTargetInterestsCode(getInterestCodeByLabel(targetInterestsNameKor));
      setTargetGender(targetGender);
    }
  }, [id, targetData?.status, targetData?.data]);
  const validateBrandName = (name) => {
    const regex = /^[a-zA-Z0-9!@#$%^&*()\-_=+<>?]{2,20}$/;
    return regex.test(name);
  };

  const handleSubmit = async () => {
    if (!targetName) {
      toast.error("타겟 이름을 입력해주세요!");
      return;
    }

    if (!validateBrandName(targetName)) {
      toast.error(
        "이름은 문자, 숫자, 특수문자(!@#$%^&*()-_+=<>?)를 포함한 2자 이상 20자 이하 여야 합니다."
      );
      return;
    }

    if (!targetInterestsCode) {
      toast.error("관심사를 선택해주세요!");
      return;
    }
    if (!targetAgeGroup) {
      toast.error("연령대를 입력해주세요!");
      return;
    }
    if (!targetGender) {
      toast.error("성별을 입력해주세요!");
      return;
    }

    const data = {
      targetName,
      targetAgeGroup: `${String(targetAgeGroup[0]).padStart(2, "0")}${String(
        targetAgeGroup[1]
      ).padStart(2, "0")}`,
      targetGender,
      targetInterestsCode,
    };

    try {
      if (isEditMode) {
        await patchMutation.mutate({
          requestUrl: `/api/target/${id}`,
          patchData: data,
        });
      } else {
        await postMutation.mutate({
          requestUrl: "/api/target",
          postData: data,
        });
      }
    } catch (error) {
      console.error("Error submitting target form:", error);
    }
  };

  useEffect(() => {
    if (
      postMutation.status === "success" ||
      patchMutation.status === "success"
    ) {
      navigate("/setting?tab=target", {
        state: { newTargetAdded: true },
      });
    }
  }, [postMutation.status, patchMutation.status, navigate]);

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper">
        <div className="flex">
          <p className="after:content-['/'] after:ml-1 after:mr-1 text-text-gray">
            사전설정
          </p>
          <p className="after:content-['/'] after:ml-1 after:mr-1">
            <button
              onClick={() => navigate("/setting?tab=target")}
              className="text-text-gray rounded-sm hover:bg-gray-lGray pl-1 pr-1"
            >
              타겟 설정
            </button>
          </p>
          <p className="text-xl font-semibold mb-12">
            {isEditMode ? "타겟 수정" : "타겟 명"}
          </p>
        </div>
        <div className="m-auto w-[780px] flex flex-col gap-5 ">
          <TargetForm
            targetName={targetName}
            setTargetName={setTargetName}
            targetAgeGroup={targetAgeGroup}
            setTargetAgeGroup={setTargetAgeGroup}
            targetInterestsCode={targetInterestsCode}
            setTargetInterestsCode={setTargetInterestsCode}
            targetGender={targetGender}
            setTargetGender={setTargetGender}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Target;
