// Common Components
import ToolTips from 'components/ToolTips/BasicToolTips';
import AIModal from 'pages/BehanceEditor/components/Modals/AIModal';

// icons
import { FaSquarePlus } from 'react-icons/fa6';
import { PiTextTBold } from 'react-icons/pi';
import { LuImage } from 'react-icons/lu';
import { FaCircle } from 'react-icons/fa';
import { useState } from 'react';

const CreateMediaCard = ({ idx, data, model, handleCreateMedia, AIModalTab, setAIModalTab }) => {
    const [isAIModal, setIsAIModal] = useState(false);
    return (
        <div>
            <div className="select-none group">
                <div className="flex opacity-0 group-hover:opacity-100 justify-center hover:my-1 items-center border-2 border-[#eb228350] border-dashed hover:h-11 h-2 my-2 text-gray-gray relative">
                    <div className="flex items-center ">
                        <FaSquarePlus className="mr-2" />
                        <div className="text-sm">미디어 삽입</div>
                    </div>
                    <div className="mx-2 text-gray-lGray ">|</div>

                    {/* CASE 1. 텍스트 삽입 */}
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCreateMedia('text', idx, '');
                        }}
                        className="mr-3 hover:text-main-red"
                    >
                        <ToolTips
                            title={'텍스트 삽입'}
                            contents={<PiTextTBold className="w-5 h-5 " />}
                        />
                    </div>

                    {/* CASE 2. 텍스트 AI 생성 */}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            setAIModalTab('AIText');
                            setIsAIModal(true);
                        }}
                        className="relative mr-3 hover:text-main-red"
                    >
                        <ToolTips
                            title={'텍스트 AI 생성'}
                            contents={<PiTextTBold className="w-5 h-5 " />}
                        />
                        <span className="absolute bottom-0 right-0">
                            <FaCircle className="absolute w-3 h-3 bottom-[-1px] right-[-3px]" />
                            <span className="absolute text-[8px] text-white bottom-0 font-bold right-[-0.5px]">AI</span>
                        </span>
                    </div>

                    {/* CASE 3. 이미지 삽입 */}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            setAIModalTab('IMAGE');
                            setIsAIModal(true);
                        }}
                        className="mr-3 hover:text-main-red"
                    >
                        <ToolTips
                            title={'이미지 삽입'}
                            contents={<LuImage className="w-5 h-5 " />}
                        />
                    </div>
                    {/* CASE 4. 이미지 AI 생성 */}
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            setAIModalTab('AIImage');
                            setIsAIModal(true);
                        }}
                        className="relative mr-3 hover:text-main-red"
                    >
                        <ToolTips
                            title={'이미지 AI 생성'}
                            contents={<LuImage className="w-5 h-5 " />}
                        />
                        <span className="absolute bottom-0 right-0">
                            <FaCircle className="absolute w-3 h-3 bottom-[-1px] right-[-3px]" />
                            <span className="absolute text-[8px] text-white bottom-0 font-bold right-[-0.5px]">AI</span>
                        </span>
                    </div>
                </div>
            </div>
            {isAIModal && (
                <AIModal
                    isShow={isAIModal}
                    setIsShow={setIsAIModal}
                    insertionIdx={idx}
                    model={model}
                    AIModalTab={AIModalTab}
                    setAIModalTab={setAIModalTab}
                    handleCreateMedia={handleCreateMedia}
                    beforeParagraph={data[idx - 1]?.contents}
                    nextParagraph={data[idx]?.contents}
                />
            )}
        </div>
    );
};
export default CreateMediaCard;
