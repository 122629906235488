import React from "react";
import loadingImg from "assets/images/loadingImg.gif";
import plum_byte_logo from "assets/logo/plum_byte_icon_logo.png";
const Loading = () => {
  return (
    <div className="w-full h-screen bg-[#00000040] z-40 fixed flex justify-center items-center left-0 top-0">
      <div className="relative">
        <img src={loadingImg} alt="loading" className="w-44 h-44" />
      </div>
    </div>
  );
};

export default Loading;
