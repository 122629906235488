// hooks
import { useQuery, useMutation } from "@tanstack/react-query";
// api
import axios from "axios";

// GET 요청을 보낼 함수 정의
export const useGetUserByToken = (requestUrl, queryKey) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      return axios.get(requestUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    // staleTime: 10 * 5 * 1000, // data 가 유지되는 시간
    // enabled: queryEnabled, // enabled 옵션을 사용하여 쿼리를 활성화 또는 비활성화합니다.
  });
  return { data, error, isLoading, refetch };
};

// POST 요청을 보낼 함수 정의
export const useRequestPost = () => {
  const mutation = useMutation({
    mutationFn: ({ requestUrl, postData }) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}${requestUrl}`,
        postData
      );
    },
  });

  return mutation;
};

// < import 해서 사용하는 법 >
// import { useGetUserByToken, useRequestPost } from "hooks/useUser";

// 1. GET 통신 테스트: data 에 응답 값 담긴다.
// const { data, error, isLoading, refetch } = useGetUserByToken(
//   "http://office.galloping.co.kr:3088/student?page=1&take=10",test
// );

// 2. POST 통신 테스트! mutation 이라는 상수에 {data, error} 등 필요한 값 담긴다.
// const mutation = useRequestPost();
// const useHandlePostData = () => {
//   mutation.mutate({
//     requestUrl: "http://office.galloping.co.kr:3088/auth/login",
//     postData: { id: "admin00", password: "pass1234!" },
//   });
// };

// 2-2. POST 요청 보내기
// <button onClick={useHandlePostData} className="border bg-red-50">
//  POST요청 연습
// </button>;

// 참고
// https://tanstack.com/query/v5/docs/framework/react/reference/useMutation
