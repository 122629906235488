import "./styles/fonts/SUIT-Variable.css";
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
// layout
import Layout from "layout/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import

// pages
import Landing from "pages/Landing";
import CreatedLanding from "pages/Landing/CreatedLanding";

import Login from "pages/Login";
import Auth from "pages/Login/Auth";
import Failure from "pages/Login/OAuth/Failure";
import Success from "pages/Login/OAuth/Success";

import Home from "pages/Home";
import Setting from "pages/Setting";
import FeedBack from "pages/FeedBack";

import KeywordPick from "pages/KeywordPick";
// import Template from "pages/Template";
// import ProjectEditor from 'pages/ProjectEditor';
import FeedBackContent from "pages/FeedBack/FeedBackContent";
import FeedBackPost from "pages/FeedBack/FeedBackPost";
import Brand from "pages/Setting/Brand";
import Target from "pages/Setting/Target";

import BehanceEditor from "pages/BehanceEditor";
import BehanceInitial from "pages/BehanceInitial";

import Projects from "pages/Projects";
import Contents from "pages/Contents";
import Template from "pages/Template";
import Templates from "pages/Templates";

import Plan from "pages/Plan";
import MyPage from "pages/MyPage";
import Notice from "pages/Notice";
import NoticeContent from "pages/Notice/NoticeContent";
import NoticePost from "pages/Notice/NoticePost";

import PaymentPage from "pages/Plan/PaymentPage/PaymentPage";
import PaymentSuccess from "pages/Plan/PaymentSuccess/PaymentSuccess";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <Layout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/login/oauth" element={<Success />} />
          <Route path="/login/authentication-failure" element={<Failure />} />

          <Route path="/landing" element={<Landing />} />
          <Route path="/created-landing" element={<CreatedLanding />} />

          <Route path="/setting" element={<Setting />} />
          <Route path="/setting/brand" element={<Brand />} />
          <Route path="/setting/brand/:id" element={<Brand />} />
          <Route path="/setting/target" element={<Target />} />
          <Route path="/setting/target/:id" element={<Target />} />

          <Route path="/plan" element={<Plan />} />
          <Route path="/plan/payment" element={<PaymentPage />} />
          <Route path="/plan/payment_success" element={<PaymentSuccess />} />
          <Route path="/mypage" element={<MyPage />} />

          <Route path="/feedBack" element={<FeedBack />} />
          <Route path="/keyword-pick" element={<KeywordPick />} />
          <Route path="/template" element={<Template />} />

          <Route path="/feedBack/:feedseq" element={<FeedBackContent />} />
          <Route path="/feedBack/post" element={<FeedBackPost />} />
          <Route path="/feedBack/edit/:id" element={<FeedBackPost />} />
          <Route path="/preset/brand" element={<Brand />} />
          <Route path="/preset/target" element={<Target />} />

          <Route path="/notification" element={<Notice />} />
          <Route path="/notification/:noticeseq" element={<NoticeContent />} />
          <Route path="/notification/post" element={<NoticePost />} />
          <Route path="/notification/edit/:id" element={<NoticePost />} />

          <Route path="/project" element={<Projects />} />
          <Route path="project/content" element={<Contents />} />
          <Route path="/project/content/templates" element={<Templates />} />
          <Route
            path="/project/content/templates/template"
            element={<Template />}
          />

          <Route path="/templates" element={<Templates />} />
          <Route path="/templates/project" element={<Projects />} />
          <Route path="/templates/project/content" element={<Contents />} />
          <Route
            path="/templates/project/content/template"
            element={<Template />}
          />

          <Route path="/behance-editor" element={<BehanceEditor />} />
          <Route path="/behance-inital" element={<BehanceInitial />} />

          <Route path="/" element={<Home />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
