import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

function ImageDragDrop({
    fileImg,
    setFileImg, // 파일 컨트롤러
    previewImg,
    setPreviewImg, // 미리보기 컨트롤러
    setSelectedCreateImgIdx,
    setSelectedProjectImgIdx, // 인덱스 컨트롤러
    dragStatePlace,
    dropPlace,
}) {
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setFileImg(file);
        if (setSelectedCreateImgIdx) setSelectedCreateImgIdx(null);
        if (setSelectedProjectImgIdx) setSelectedProjectImgIdx(null);
        const reader = new FileReader();
        reader.onload = (e) => {
            setPreviewImg(e.target.result);
        };

        reader.readAsDataURL(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
    });

    const handleUpload = async () => {
        if (!fileImg) return;

        const formData = new FormData();
        formData.append('fileImg', fileImg);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('fileImg uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading fileImg:', error);
        }
    };
    return (
        <div className="w-full h-full overflow-auto rounded-md bg-[#F8F9FA]">
            <div
                {...getRootProps({ className: 'dropzone' })}
                className="flex items-center justify-center w-full h-full"
            >
                <input {...getInputProps()} />
                {isDragActive ? dragStatePlace : !previewImg ? dropPlace : null}
                {previewImg && (
                    <img
                        src={previewImg}
                        className="max-w-full max-h-full"
                        alt="previewImg"
                    />
                )}
            </div>
        </div>
    );
}

export default ImageDragDrop;
