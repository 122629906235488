// react
import { Link, useLocation } from "react-router-dom";
// utils
import { NavbarMatcherToEng } from "utils/NavbarMatcher";
// images
import horizontal_logo from "assets/logo/horizontal_logo.png";
// icons
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FiFolderPlus } from "react-icons/fi";
import { FiLayout } from "react-icons/fi";
import { IoDiamondOutline } from "react-icons/io5";
import { LuSettings2 } from "react-icons/lu";
import { LuCrown } from "react-icons/lu";
import { RiVideoLine } from "react-icons/ri";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { AiOutlineNotification } from "react-icons/ai";
import { useState } from "react";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsNavOpen, toggleIsNavOpen } from "../../redux/isNavOpenSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const navOpen = useSelector((state) => state.navOpen.isNavOpen);
  const location = useLocation();
  const locationList = location.pathname.split("/");
  const [actAnimation, setActAnimation] = useState(false);

  const handleClickNav = () => {
    setActAnimation(true);
    dispatch(toggleIsNavOpen());
  };
  const navList = [
    {
      title: "프로젝트",
      link: "project",
      icon: <FiFolderPlus className="w-4 h-4" />,
    },
    {
      title: "템플릿",
      link: "templates",
      icon: <FiLayout className="w-4 h-4" />,
    },
    {
      title: "키워드픽",
      link: "keyword-pick",
      icon: <IoDiamondOutline className="w-4 h-4" />,
    },
    {
      title: "사전설정",
      link: "setting",
      icon: <LuSettings2 className="w-4 h-4 rotate-90" />,
    },
  ];
  const bottomNavList = [
    { title: "요금제", link: "plan", icon: <LuCrown className="w-4 h-4" /> },
    // {
    //     title: '가이드',
    //     link: 'help',
    //     icon: <RiVideoLine className="w-4 h-4" />,
    // },
    {
      title: "문의사항",
      link: "feedBack",
      icon: <IoPaperPlaneOutline className="w-4 h-4" />,
    },
    {
      title: "공지사항",
      link: "notification",
      icon: <AiOutlineNotification className="w-4 h-4" />,
    },
  ];
  return (
    <nav className={`fixed left-0 z-30 h-screen select-none w-44 `}>
      <div className="h-16">
        <Link to="/">
          <img
            alt="horizontal_logo"
            src={horizontal_logo}
            className="w-24 m-6"
          />
        </Link>
      </div>
      {navOpen ? (
        <div
          className={`${
            actAnimation ? "slidein" : ""
          } w-full h-full items-center pl-4 pb-14 bg-[#1C1621] rounded-r-3xl inline-block`}
        >
          <div className="flex justify-end m-4 text-gray-dGray">
            <MdKeyboardDoubleArrowLeft
              onClick={handleClickNav}
              className="w-5 h-5"
            />
          </div>

          <div className="flex flex-col justify-between h-full pb-[10rem] text-base">
            <ul className="w-40 mt-6 text-[14px]">
              {navList &&
                navList.map((el, idx) => (
                  <li
                    key={idx}
                    className={
                      locationList[1] === NavbarMatcherToEng(el.title)
                        ? "text-text-white h-12"
                        : "text-[#B09DEA] h-12"
                    }
                  >
                    <Link
                      to={`/${el.link}`}
                      className="flex items-center pb-6 font-semibold w-fit"
                    >
                      <div>{el.icon}</div>
                      <div className="ml-2">{el.title}</div>
                    </Link>
                  </li>
                ))}
            </ul>
            <ul className="w-40 pb-10 text-[14px]">
              {bottomNavList &&
                bottomNavList.map((el, idx) => (
                  <li
                    key={idx}
                    className={
                      locationList[1] === NavbarMatcherToEng(el.title)
                        ? "text-text-white h-12"
                        : "text-[#B09DEA] h-12"
                    }
                  >
                    <Link
                      to={`/${el.link}`}
                      className="flex items-center pb-6 font-semibold w-fit"
                    >
                      <div>{el.icon}</div>
                      <div className="ml-2">{el.title}</div>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : (
        <div
          className={`${
            actAnimation ? "" : ""
          } h-full items-center pb-14 bg-[#1C1621] rounded-r-3xl inline-block w-16`}
        >
          <div className="flex justify-end mx-6 my-4 text-gray-dGray">
            <MdKeyboardDoubleArrowRight
              className="w-5 h-5"
              onClick={handleClickNav}
            />
          </div>

          <div className="flex flex-col h-full pb-[10rem] text-base justify-between my-4 mx-6">
            <ul className="flex flex-col items-end w-full mt-6">
              {navList &&
                navList.map((el, idx) => (
                  <li
                    key={idx}
                    className={
                      locationList[1] === NavbarMatcherToEng(el.title)
                        ? "text-text-white h-12"
                        : "text-[#B09DEA] h-12"
                    }
                  >
                    <Link
                      to={`/${el.link}`}
                      className="flex items-center justify-between pb-6 font-semibold w-fit"
                    >
                      <div>{el.icon}</div>
                      {/* <div className="ml-2">{el.title}</div> */}
                    </Link>
                  </li>
                ))}
            </ul>
            <ul className="flex flex-col items-end w-full pb-10">
              {bottomNavList &&
                bottomNavList.map((el, idx) => (
                  <li
                    key={idx}
                    className={
                      locationList[1] === NavbarMatcherToEng(el.title)
                        ? "text-text-white h-12"
                        : "text-[#B09DEA] h-12"
                    }
                  >
                    <Link
                      to={`/${el.link}`}
                      className="flex items-center justify-end pb-6 font-semibold w-fit"
                    >
                      <div className="w-fit">{el.icon}</div>
                      {/* <div className="ml-2">{el.title}</div> */}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
