// hooks
import { useEffect, useState, useRef } from 'react';
// icons
import { LuPencilLine } from 'react-icons/lu';
// Behance Modals
import HandleTextModal from 'pages/BehanceEditor/components/Modals/HandleTextModal';
const TextMediaCard = ({ idx, data, setData, isFocus, setCurFocusCreatedIdx, contents }) => {
    const ref = useRef();
    const handleClick = (e) => {
        if (isFocus) {
            e.stopPropagation();
        }
        setCurFocusCreatedIdx(idx);
    };

    const textContentDelete = () => {
        const newData = [...data];
        newData.splice(idx, 1);
        setData(newData);
    };

    const textContentReWrite = (newContent) => {
        const newData = [...data];
        newData.splice(idx, 1, { type: 'text', contents: newContent });
        setData(newData);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (shouldInsertLineBreak(e)) {
                e.preventDefault(); // 기본 Enter 키 동작 방지
                insertLineBreak();
            }
        }
    };

    const shouldInsertLineBreak = (e) => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;

        const range = selection.getRangeAt(0);
        const commonAncestor = range.commonAncestorContainer;

        // ul 태그 안에 있는지 확인하는 함수
        const isInsideList = (node) => {
            while (node) {
                if (node.nodeName === 'UL' || node.nodeName === 'OL') {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        };

        return !isInsideList(commonAncestor);
    };

    const insertLineBreak = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;

        const range = selection.getRangeAt(0);
        range.deleteContents();

        const br = document.createElement('br');
        const space = document.createTextNode('\u200B'); // Zero-width space to keep the cursor in the new line

        range.insertNode(space);
        range.insertNode(br);

        // Move the cursor to the end of the new line
        range.setStartAfter(space);
        range.setEndAfter(space);
        selection.removeAllRanges();
        selection.addRange(range);
    };

    useEffect(() => {
        if (ref && isFocus) {
            ref.current.focus();
        }
    }, [isFocus, data]);
    return (
        <div className="relative group">
            <HandleTextModal
                textContent={data[idx].contents}
                textContentReWrite={textContentReWrite}
                textContentDelete={textContentDelete}
            />

            <div>
                <div
                    // class="text-media-card"
                    contentEditable
                    suppressContentEditableWarning
                    dangerouslySetInnerHTML={{ __html: contents }}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    autoFocus={isFocus}
                    tabIndex={0}
                    ref={ref}
                    className="w-full p-1 text-media-card focus:outline-main-pink min-h-10 group-hover:border group-hover:border-main-pink"
                />
                {/* {contents}
        </div> */}
            </div>
        </div>
    );
};

export default TextMediaCard;
