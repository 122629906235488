export const getFeedbackTypeText = (feedbackType) => {
  switch (feedbackType) {
    case "ALL":
      return "전체";
    case "SERVICE_SUGGESTION":
      return "서비스제안";
    case "BUG_REPORT":
      return "오류접수";
    case "FREE_OPINION":
      return "자유의견";
    default:
      return "알 수 없음";
  }
};
