// hooks
import { useState } from 'react';
// icons
import { MdOutlineRefresh } from 'react-icons/md';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
import { FaRegCircleDot } from 'react-icons/fa6';
import { FaRegCircle } from 'react-icons/fa';
// redux

const CreateTextModal = ({ setIsShow, createdAiTexts, insertionIdx, handleCreateMedia, handlePostAI }) => {
    const [curIdx, setCurIdx] = useState(1);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const nextIdx = () => {
        if (curIdx < createdAiTexts.length) {
            setCurIdx(curIdx + 1);
        }
    };
    const beforeIdx = () => {
        if (curIdx > 1) {
            setCurIdx(curIdx - 1);
        }
    };
    const handleMedia = () => {
        handleCreateMedia('text', insertionIdx, createdAiTexts[selectedIdx - 1]);
        setIsShow(false);
    };
    return (
        <div
            // onClick={handleImageModalClick}
            onClick={(e) => e.stopPropagation()}
            className="p-3 border shadow-md m-auto shadow-[#eb228330] rounded-lg w-[40rem] h-[22rem] bg-text-white border-main-red"
        >
            <div className="h-[15rem]">
                <div
                    onClick={() => setSelectedIdx(curIdx)}
                    className="flex items-start p-2 rounded-lg bg-gray-sGray"
                >
                    <div className="w-8 mt-1">
                        {curIdx === selectedIdx ? (
                            <FaRegCircleDot className="w-8 text-main-pink" />
                        ) : (
                            <FaRegCircle className="w-8 text-gray-dGray" />
                        )}
                    </div>
                    <div>{createdAiTexts[curIdx - 1]}</div>
                </div>
            </div>
            <div className="flex h-[2.6rem] items-center justify-between w-[7rem] m-auto">
                <FaChevronLeft onClick={beforeIdx} />
                <div>
                    <span className="text-main-pink">{curIdx}</span>
                    <span className="mx-1">/</span>
                    <span>{createdAiTexts.length}</span>
                </div>
                <FaChevronRight onClick={nextIdx} />
            </div>

            <div className="flex items-center justify-between mt-2">
                <button
                    onClick={() => setIsShow(false)}
                    className="text-sm text-gray-dGray"
                >
                    취소
                </button>
                <div className="flex">
                    <button
                        onClick={(e) => createdAiTexts.length > 0 && handlePostAI(e)}
                        className="flex items-center px-2 py-1 mr-1 text-sm border rounded-lg text-gray-gray border-gray-gray"
                    >
                        <MdOutlineRefresh className="w-5 h-5 mr-1" />
                        (-1Byte)재생성
                    </button>
                    <button
                        onClick={handleMedia}
                        className="flex items-center px-8 py-1 border rounded-lg bg-main-red text-text-white"
                    >
                        사용
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateTextModal;
