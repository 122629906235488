const planConfig = [
  {
    title: "나노",
    eng: "NANO",
    price: "5900",
    byte: "100",
    checkList: [
      "블로그 기준 30회 생성 가능",
      "100Byte 매월 자동 충전",
      "고퀄리티 이미지 생성",
      "트렌드 순위 제공",
    ],
    btnTitle: "나노로 시작",
  },
  {
    title: "베이직",
    eng: "BASIC",
    price: "14800",
    byte: "400",
    checkList: [
      "블로그 기준 100회 생성 가능",
      "400Byte 매월 자동 충전",
      "고퀄리티 이미지 생성",
      "트렌드 순위 제공",
    ],
    btnTitle: "베이직으로 시작",
  },
  {
    title: "클래식",
    eng: "CLASSIC",
    price: "33700",
    byte: "1000",
    checkList: [
      "블로그 기준 300회 생성 가능",
      "1000Byte 매월 자동 충전",
      "고퀄리티 이미지 생성",
      "트렌드 순위 제공",
    ],
    btnTitle: "클래식으로 시작",
  },
  {
    title: "프라임",
    eng: "PRIME",
    price: "99600",
    byte: "3000",
    checkList: [
      "블로그 기준 1000회 생성 가능",
      "3000Byte 매월 자동 충전",
      "고퀄리티 이미지 생성",
      "트렌드 순위 제공",
    ],
    btnTitle: "프라임으로 시작",
  },
];

export default planConfig;
