// type: default, success, fail, disabled
const SettingTextarea = ({
  id,
  name,
  type = "default",
  value,
  setValue,
  rows,
  cols,
  maxLength,
  placeholder,
}) => {
  const defaultStyle =
    "text-sm bg-white focus:outline-1 focus:outline-main-pink w-full py-2.5 px-4 rounded-lg resize-none placeholder:text-text-gray";
  const successStyle =
    "text-sm bg-text-white focus:outline-status-green border-2 border-status-green w-full p-2.5 rounded-lg resize-none";
  const failStyle =
    "text-sm bg-text-white focus:outline-status-red border-2 border-status-red w-full p-2.5 rounded-lg resize-none";
  const disabledStyle =
    "text-sm bg-gray-sGray text-text-gray w-full p-2.5 rounded-lg resize-none";

  return (
    <textarea
      id={id}
      name={name}
      onChange={(e) => setValue(e.target.value)}
      rows={rows}
      cols={cols}
      maxLength={maxLength}
      disabled={type === "disabled"}
      placeholder={placeholder}
      value={value}
      className={
        type === "default"
          ? defaultStyle
          : type === "success"
          ? successStyle
          : type === "fail"
          ? failStyle
          : type === "disabled"
          ? disabledStyle
          : defaultStyle
      }
    >
      {value}
    </textarea>
  );
};

export default SettingTextarea;
