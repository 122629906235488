import { configureStore } from '@reduxjs/toolkit';
import textReducer from './aiTextSlice';
import imageReducer from './aiImageSlice';
import mobileReducer from './isMobileSlice';
import navOpenReducer from './isNavOpenSlice';
import refetchPlumReducer from './isRefetchPlum';

const store = configureStore({
    reducer: {
        text: textReducer,
        image: imageReducer,
        mobile: mobileReducer,
        navOpen: navOpenReducer,
        refetchPlum: refetchPlumReducer,
    },
});

export default store;
