// hooks
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// Landing Components
import LandingHeader from "pages/Landing/components/LandingHeader";
// images
import vertical_logo from "assets/logo/vertical_logo.png";
// icons
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
// OAuth Buttons
import GoogleBtn from "pages/Login/components/OAuthButtons/GoogleBtn";
import NaverBtn from "pages/Login/components/OAuthButtons/NaverBtn";
import KakaoBtn from "pages/Login/components/OAuthButtons/KakaoBtn";
// images
import login_main from "assets/login/login_main.webp";
import login_typo from "assets/login/login_typo.png";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsMobile } from "../../redux/isMobileSlice";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobile = useSelector((state) => state.mobile.isMobile);
  // 모바일 사이즈설정
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 1224;
      dispatch(setIsMobile(isMobile));
    };
    // 초기 상태 설정
    handleResize();
    // 윈도우 리사이즈 이벤트에 핸들러를 연결
    window.addEventListener("resize", handleResize);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <div
      className={
        mobile
          ? "flex justify-center items-center w-screen h-screen"
          : "grid w-screen h-screen grid-cols-2 "
      }
    >
      {/* <LandingHeader /> */}

      {!mobile && (
        <div className="">
          <img
            onClick={() => navigate("/landing")}
            alt="login_main"
            src={login_main}
            loading="lazy"
            className="absolute inset-0 object-cover w-[50%] h-full"
            // "fixed top-0 left-0 w-full h-full object-cover"
          />
        </div>
      )}

      <div
        className={`${
          mobile ? "max-w-[300px] w-full" : "w-[384px]"
        } m-auto items-center justify-center`}
      >
        <div className="text-center">
          <img src={login_typo} alt="login_typo" className="w-[134px] m-auto" />
          <div className=" mt-8 ">
            <NaverBtn />
            <GoogleBtn />
            <KakaoBtn />
            <Link to="/auth">
              <button className="flex items-center justify-center w-full px-12 py-3 my-4 border rounded-lg border-gray-lGray">
                <CiMail className="w-5 h-5 mr-4 rounded-md text-gray-dGray" />
                <div className="text-sm font-medium">
                  이메일 계정으로 시작하기
                </div>
              </button>
            </Link>
          </div>

          <div className="mt-8 text-xs text-center">
            <div className="mb-2 text-gray-dGray">
              로그인하면 정책에 모두 동의하게 됩니다.
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.notion.so/29a93af98895492f8e0af775d60c7a38"
                rel="noreferrer"
                className="mr-2 font-semibold text-gray-dGray"
              >
                이용약관
              </a>
              <a
                target="_blank"
                href="https://www.notion.so/04f01263547f442e87f62a239449f8fb"
                rel="noreferrer"
                className="font-semibold text-gray-dGray"
              >
                개인정보처리방침
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
