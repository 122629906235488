import React from "react";
import { FaCheck } from "react-icons/fa";
import ByteBadge from "components/Badge/ByteBadge";

const PaymentCard = ({ title, price, checkList, byte }) => {
  return (
    <div className="payment-card bg-white p-4 rounded-xl max-w-sm w-full h-[280px]">
      <h3 className="text-lg font-semibold">{title}</h3>
      <div className="flex gap-2 items-end">
        <div className="text-main-red text-2xl font-bold">
          {Number(price).toLocaleString("ko-KR")}원
          <span className="text-sm text-text-gray font-normal">/월</span>
        </div>
        <ByteBadge byte={byte} />
      </div>

      <ul className="mt-4">
        {checkList.map((list, idx) => (
          <li key={idx} className="flex text-sm items-center mt-2">
            <FaCheck className="text-main-red w-3 h-3 mr-1" />
            {list}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PaymentCard;
