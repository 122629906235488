import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useRequestGet, useRequestDelete } from "hooks/useRequest";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import ContentView from "../components/ContentView";
import { useQueryClient } from "@tanstack/react-query";

function NoticeContent() {
  let { noticeseq } = useParams();

  const navigate = useNavigate();
  const [contentData, setContentData] = useState({});

  const queryClient = useQueryClient();

  const getNoticeContent = useRequestGet(`/api/notice/${noticeseq}`, [
    "noticeContent",
    noticeseq,
  ]);

  useEffect(() => {
    if (getNoticeContent.status === "success" && getNoticeContent.data) {
      setContentData(getNoticeContent.data.data);
    } else if (getNoticeContent.status === "error") {
      console.log("피드 내용 요청 실패");
    }
  }, [getNoticeContent.data]);

  const deleteNoticeMutation = useRequestDelete(["noticeContent", noticeseq]);

  const handleDelete = () => {
    deleteNoticeMutation.mutate(
      { url: `/api/notice/${noticeseq}` },
      {
        onSuccess: () => {
          navigate("/notification");
        },
      }
    );
  };

  const handleNavigation = (seq) => {
    queryClient.invalidateQueries(["noticeContent", seq]);
    navigate(`/notification/${seq}`);
  };

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper pb-10">
        <h3 className="text-lg font-semibold mb-7">공지사항</h3>
        <div>
          <ContentView
            title={contentData.noticeTitle}
            createdAt={contentData.createdAt}
            count={contentData.noticeViewCount}
            noticeHtml={contentData.noticeHtml}
            noticeseq={contentData.seq}
            handleDelete={handleDelete}
            isPinned={contentData.isPinned}
            userSeq={contentData.userSeq}
          />
        </div>
        <div className="flex items-center justify-center gap-6 pt-3">
          <button
            className={`flex items-center gap-2 ${
              contentData.previousSeq ? "" : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() =>
              contentData.previousSeq &&
              handleNavigation(contentData.previousSeq)
            }
            disabled={!contentData.previousSeq}
          >
            <FaAngleLeft />
            이전글
          </button>

          <Link to="/notification">
            <button className="w-[129px] text-sm bg-main-red text-text-white h-8 rounded-md">
              목록
            </button>
          </Link>

          <button
            className={`flex items-center gap-2 ${
              contentData.nextSeq ? "" : "text-gray-400 cursor-not-allowed"
            }`}
            onClick={() =>
              contentData.nextSeq && handleNavigation(contentData.nextSeq)
            }
            disabled={!contentData.nextSeq}
          >
            다음글
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoticeContent;
