import { useNavigate } from "react-router-dom";

import { FaCircleCheck } from "react-icons/fa6";
import CustomButton from "components/Buttons/CustomButton";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper">
        <div className="mt-40">
          <div className="text-center m-auto">
            <FaCircleCheck className="m-auto text-main-red w-8 h-8 " />
            <p className="my-4 text-xl font-semibold">결제가 완료되었습니다</p>
          </div>
          <div className="flex gap-3 justify-center">
            <CustomButton
              content="제작하러가기"
              customStyle={
                "bg-main-red text-text-white py-1.5 rounded-lg text-sm w-[150px]"
              }
              func={() => navigate("/project")}
            />
            <CustomButton
              content="홈으로 가기"
              customStyle={
                "text-sm bg-white text-text-gray py-1.5 rounded-lg border-text-lGray border w-[150px]"
              }
              func={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentSuccess;
