export const dateMatcher = (inputTime) => {
    const currentTime = new Date();
    const inputDateTime = new Date(inputTime);

    const timeDiff = currentTime - inputDateTime; // 밀리초 단위의 시간 차이
    const minutesDiff = Math.floor(timeDiff / (1000 * 60)); // 분 단위의 시간 차이
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60)); // 시간 단위의 시간 차이

    if (minutesDiff < 10) {
        return '방금전';
    } else if (minutesDiff < 60) {
        return `${minutesDiff}분 전`;
    } else if (hoursDiff < 5) {
        return `${hoursDiff}시간 전`;
    } else if (inputDateTime.toDateString() === currentTime.toDateString()) {
        return 'TODAY';
    } else if (inputDateTime.toDateString() === new Date(currentTime.getTime() - 24 * 60 * 60 * 1000).toDateString()) {
        return '어제';
    } else {
        return `${inputDateTime.getFullYear()}년 ${inputDateTime.getMonth() + 1}월 ${inputDateTime.getDate()}일`;
    }
};
