import React from "react";
import PlanCard from "components/Card/PlanCard";
import { useRequestGet } from "hooks/useRequest";
import planConfig from "./planConfig";
import ByteBadge from "components/Badge/ByteBadge";
import BasicModal from "components/Modals/BasicModal";
import { PlanMatcherToKor } from "utils/PlanMatcher";
import { FaCheck } from "react-icons/fa";
import CustomButton from "components/Buttons/CustomButton";
import CorporateModal from "./components/CorporateModal";
import { toast } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify 스타일 import
import corporateFunc from "./components/corporateFunc";

const Plan = () => {
  const getUserInfo = useRequestGet("/api/user", "userInfo");
  const getCustomertKey = useRequestGet(
    "/api/user/customer-key",
    "customerKey"
  );

  // 요금제와 가격 매핑 상수
  const planPrices = {
    FREE: 0,
    NANO: 5900,
    BASIC: 14800,
    CLASSIC: 33700,
    PRIME: 99600,
  };

  const PlanMatcherToPrice = (plan) => planPrices[plan] || 0;

  // 로딩 상태 처리
  if (getUserInfo.isLoading || getCustomertKey.isLoading) {
    return <div>로딩 중...</div>;
  }

  const userInfo = getUserInfo?.data?.data;
  const username = userInfo?.name;
  const currentPlan = userInfo?.planName;
  const createdAt = userInfo?.createdAt;
  const currentPrice = PlanMatcherToPrice(currentPlan);
  const customerKey = getCustomertKey.data?.data?.customerKey;
  const remainingPlum = userInfo?.remainingPlum;
  const expirationDate = userInfo?.expirationDate;

  const handleCorporateSubmit = async (
    companyName,
    industry,
    email,
    contact,
    message
  ) => {
    toast.success("기업 문의가 접수되었습니다.");
    const result = await corporateFunc(
      companyName,
      industry,
      email,
      contact,
      message
    );

    if (result.success) {
      toast.success(result.message); // 성공 시 메시지 출력
    }
  };

  return (
    <div className="all-bg-color wrapper">
      <div className="space-y-2 cherry-wrapper">
        <div>
          <h3 className="text-xl font-bold mt-[5px]">작지만 강력한 요금제</h3>
          <p className="text-sm text-main-red mt-2">
            한입에 담긴 플럼바이트의 강력한 성능을 누리세요
          </p>
        </div>

        {/* 조건부 렌더링 */}
        {userInfo && (
          <div className="flex flex-col justify-center items-center gap-1">
            <p className="text-base text-text-dGray font-semibold">
              {PlanMatcherToKor(currentPlan)} 요금제 이용중
            </p>
            <div className="flex gap-2 items-center">
              <div className="text-[32px] text-text-dGray">
                {Number(currentPrice).toLocaleString("ko-KR")}원
                <span className="text-lg text-text-gray">/월</span>
              </div>
              <ByteBadge byte={remainingPlum} date={expirationDate} />
            </div>
          </div>
        )}

        <div className="flex flex-wrap gap-2.5 justify-center">
          {planConfig.map((plan, index) => (
            <PlanCard
              username={username}
              email={userInfo?.userId}
              key={index}
              price={plan.price}
              byte={plan.byte}
              checkList={plan.checkList}
              title={plan.title}
              btnTitle={plan.btnTitle}
              customerKey={customerKey}
              eng={plan.eng}
              userCurrentPlan={currentPlan}
              createdAt={createdAt}
            />
          ))}
        </div>
        <p className="text-text-lGray text-sm text-right">
          충전하신 바이트는 한 달 이내 사용하셔야 합니다.(미사용시 소멸)
        </p>

        <div className="flex gap-3 justify-center pb-16">
          <p className="flex gap-1 items-center text-text-dGray text-sm">
            <FaCheck className="text-main-red w-3 h-3 mr-1" />더 많은 기능이
            필요하신가요?
          </p>
          <BasicModal
            backgroundStyle="gradient"
            title="기업 이용 문의"
            content={<CorporateModal onSubmit={handleCorporateSubmit} />}
            modalBtn={
              <CustomButton
                content="기업 고객 문의하기"
                customStyle="border border-main-red py-2 px-4 rounded-lg text-sm w-fit text-main-red"
              />
            }
            activeBtnText={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Plan;
