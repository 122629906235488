import { useState } from "react";
// utils
import { MarkdownToHtml } from "utils/MarkdownToHtml";

// utils 폴더에 있는 순수 함수로 작성
export const ReCreateContentsChanger = (
  templateCode,
  reCreatedText,
  reCreatedImage
) => {
  const newData = [];
  const imageRecommendationPattern = /<이미지 추천:\s*(.*?)>/;
  // const isHasTitle =
  //   201 <= Number(templateCode?.slice(1)) &&
  //   Number(templateCode?.slice(1)) <= 542;
  // console.log(Number(templateCode?.slice(1)), isHasTitle);
  if (reCreatedImage) {
    newData.push({
      type: "image",
      contents: reCreatedImage,
    });
  }

  if (reCreatedText) {
    const initialData = reCreatedText.split("\n\n");
    initialData.forEach((el) => {
      const match = el.match(imageRecommendationPattern);
      if (match) {
        newData.push({
          type: "recommand",
          contents: match[1], // 이미지 추천 뒤의 텍스트
        });
      } else {
        newData.push({
          type: "text",
          contents: el,
        });
      }
    });

    // Markdown을 HTML로 변환
    const convertedHtmlData = handleMarkdown(newData);
    return convertedHtmlData;
  }
};

// 내부 데이터 -> HTML로 변환하는 함수
const handleMarkdown = (markdown) => {
  if (markdown?.length > 0) {
    return markdown.map((item) =>
      item.type === "text"
        ? { ...item, contents: MarkdownToHtml(item.contents) }
        : item
    );
  }
  return [];
};
