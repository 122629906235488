import React from "react";
import { FaCheck } from "react-icons/fa6";

const GenderRadio = ({ targetGender, setTargetGender }) => {
  const genderOptions = [
    { label: "남성", value: "male" },
    { label: "여성", value: "female" },
    { label: "모두", value: "male,female" },
  ];

  return (
    <div className="flex gap-4">
      {genderOptions.map((option) => (
        <label
          key={option.value}
          className={`flex items-center cursor-pointer border py-2 px-4 bg-text-white rounded-lg 
            ${
              targetGender === option.value
                ? "border-main-red"
                : "border-text-gray"
            }`}
          onClick={() => setTargetGender(option.value)}
        >
          <div className="flex items-center justify-center">
            <FaCheck
              className={` w-5 h-5 mr-2 ${
                targetGender === option.value
                  ? "text-main-red"
                  : "text-text-gray"
              }`}
            />
          </div>
          <span
            className={`font-semibold ${
              targetGender === option.value ? "text-main-red" : "text-text-gray"
            }`}
          >
            {option.label}
          </span>
        </label>
      ))}
    </div>
  );
};

export default GenderRadio;
