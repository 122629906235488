// hooks
import { useState, useEffect } from 'react';
import { useRequestGet } from 'hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { useFormDataPost } from 'hooks/useRequest';

// icons
import { LuPencilLine } from 'react-icons/lu';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdOutlineRefresh } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
// common
import ImageDragDrop from 'components/ImageDragDrop';
// redux
import { useSelector } from 'react-redux';
const CreateImageModal = ({
    setIsShow,
    insertionIdx,
    handleCreateMedia,
    handleEditMedia,
    createdAIImage,
    handlePostAI,
}) => {
    const reCreateAIImage = useSelector((state) => state.image.reCreateAIImage);
    const [searchParams] = useSearchParams();
    const projectSeq = searchParams.get('projectSeq');
    const getProjectImg = useRequestGet(`api/project/${projectSeq}/images?page=1&size=100`, 'projectImages');

    const [previewImg, setPreviewImg] = useState(null);
    const [fileImg, setFileImg] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [selectedCreateImgIdx, setSelectedCreateImgIdx] = useState(null);
    const [selectedProjectImgIdx, setSelectedProjectImgIdx] = useState(null);
    const [tab, setTab] = useState('create');
    const handleDBImgChange = (idx, previewImgURL, imageURL) => {
        setPreviewImg(previewImgURL);
        setFileImg(null);
        setFileURL(imageURL);
        if (tab === 'create') {
            setSelectedCreateImgIdx(idx);
            setSelectedProjectImgIdx(null);
        } else {
            setSelectedProjectImgIdx(idx);
            setSelectedCreateImgIdx(null);
        }
    };

    const handleImageModalClick = (e) => {
        e.stopPropagation();
    };

    const handleMedia = () => {
        if (fileImg) {
            //새로 파일을 선택하는 경우
            postNewImage();
        } else if (fileURL) {
            //DB에 이미 URL 이 유효한 파일을 선택하는 경우
            if (handleCreateMedia) handleCreateMedia('image', insertionIdx, fileURL);
            else if (handleEditMedia) {
                handleEditMedia(fileURL);
            }
            setIsShow(false);
        }
        setSelectedCreateImgIdx(null);
        setSelectedProjectImgIdx(null);
    };

    // 이미지 업로드 요청
    const postImage = useFormDataPost();
    const postNewImage = () => {
        // fileImgLists에 쌓인 이미지들을 업로드
        if (fileImg) {
            const formData = new FormData();
            formData.append('images', fileImg);
            // 업로드 요청 함수 호출
            postImage.mutate({
                requestUrl: `/api/project/${projectSeq}/images`,
                postData: formData,
            });
        }
    };

    useEffect(() => {
        if (postImage.status === 'success') {
            // 이미지 data 삽입
            handleCreateMedia('image', insertionIdx, postImage?.data?.data[0].imageS3Url);
            // 모달 종료
            setIsShow(false);
            setFileImg(null);

            // refetch
            getProjectImg.refetch();
        }
    }, [postImage.status]);

    return (
        <div
            onClick={handleImageModalClick}
            className="p-3 border shadow-md m-auto shadow-[#eb228330] rounded-lg w-[40rem] h-[22rem] bg-text-white border-main-red"
        >
            <div className="flex">
                <div className="w-1/2">
                    {/* Select Image Map */}
                    <div className="grid grid-cols-2 gap-1 p-1 mb-2 text-center rounded-md text-text-gray bg-gray-sGray">
                        <button
                            onClick={() => setTab('create')}
                            className={`p-[3px] rounded-md text-sm  ${
                                tab === 'create' && 'bg-text-white text-main-red'
                            }`}
                        >
                            생성이미지
                        </button>
                        <button
                            onClick={() => setTab('project')}
                            className={`p-[1px] rounded-md text-sm  ${
                                tab === 'project' && 'bg-text-white text-main-red'
                            }`}
                        >
                            프로젝트 이미지
                        </button>
                    </div>
                    <div className="grid grid-cols-3 gap-1 overflow-y-scroll h-[15rem]">
                        {tab === 'create'
                            ? createdAIImage?.map((el, idx) => (
                                  <div
                                      key={idx}
                                      className="relative hover:border h-fit hover:border-main-red"
                                      onClick={() => handleDBImgChange(idx, el.imageS3Url, el.imageS3Url)}
                                  >
                                      <img
                                          alt={el.originalImageName}
                                          className="object-cover h-[6rem] w-[6rem]"
                                          src={el.imageS3Url}
                                      />
                                      {idx === selectedCreateImgIdx && (
                                          <div className="bg-[#00000080] absolute w-full h-full left-0 top-0 flex justify-center items-center z-30">
                                              <FaCheck className="w-6 h-6 text-white" />
                                          </div>
                                      )}
                                  </div>
                              ))
                            : getProjectImg?.data?.data?.data.map((el, idx) => (
                                  <div
                                      key={idx}
                                      className="relative hover:border h-fit hover:border-main-red"
                                      onClick={() => handleDBImgChange(idx, el.imageS3Url, el.imageS3Url)}
                                  >
                                      <img
                                          alt={el.originalImageName}
                                          className="object-cover h-[6rem] w-[6rem]"
                                          src={el.imageS3Url}
                                      />
                                      {idx === selectedCreateImgIdx && (
                                          <div className="bg-[#00000080] absolute w-full h-full left-0 top-0 flex justify-center items-center z-30">
                                              <FaCheck className="w-6 h-6 text-white" />
                                          </div>
                                      )}
                                  </div>
                              ))}
                    </div>
                </div>

                {/* 이미지 Drag & Drops */}

                <div className="w-1/2 pl-2 h-[18rem]">
                    <div className="relative flex items-center justify-center w-full h-full border-2 border-dashed rounded-lg bg-back-lGray border-gray-gray">
                        <ImageDragDrop
                            fileImg={fileImg}
                            setFileImg={setFileImg}
                            previewImg={previewImg}
                            setPreviewImg={setPreviewImg}
                            setSelectedCreateImgIdx={setSelectedCreateImgIdx}
                            setSelectedProjectImgIdx={setSelectedProjectImgIdx}
                            dragStatePlace={
                                <div className="bg-[#eb228320] rounded-lg w-full h-full absolute left-0 top-0 flex justify-center items-center text-main-pink text-lg">
                                    Drop Here
                                </div>
                            }
                            dropPlace={
                                <div className="flex flex-col items-center justify-center text-gray-dGray">
                                    <div className="text-xs text-center">이미지 선택 및 드래그하거나</div>
                                    <div className="text-xs text-center">
                                        <span className="font-bold border-b text-main-pink border-main-pink">
                                            첨부파일
                                        </span>
                                        을 업로드해주세요
                                    </div>
                                    <button className="px-2 py-1 mt-4 text-sm border rounded-lg border-gray-lGray">
                                        Filte Upload
                                    </button>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="flex justify-center mt-2">
                <button
                    onClick={(e) => tab === 'create' && reCreateAIImage && handlePostAI(e, reCreateAIImage)}
                    className={
                        tab === 'create' && reCreateAIImage
                            ? 'flex items-center px-2 py-1 mr-1 text-sm border rounded-lg text-gray-gray border-gray-gray '
                            : 'flex items-center px-2 py-1 mr-1 text-sm border rounded-lg text-gray-gray border-gray-gray bg-gray-sGray'
                    }
                >
                    <MdOutlineRefresh className="w-5 h-5 mr-1" />
                    (-2Byte)재생성
                </button>
                <button
                    onClick={handleMedia}
                    className="flex items-center px-8 py-1 border rounded-lg bg-main-red text-text-white"
                >
                    사용
                </button>
            </div>
        </div>
    );
};

export default CreateImageModal;
