// hooks
import { useState, useEffect } from "react";
import { useReqDelete, useRequestPost } from "hooks/useRequest";
// icons
import { AiOutlineMore } from "react-icons/ai";
import { CiImageOn } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuImage } from "react-icons/lu";
// project components
import DeleteConfirmModal from "pages/Projects/DeleteConfirmModal";

const ProjectHandleModal = ({ seq, refetchFunc, setImageUploadModal }) => {
  const [isShow, setShow] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const handleShow = () => {
    setShow(!isShow);
  };

  const deleteProject = useReqDelete();
  const handleDeleteProject = () => {
    if (seq && deleteProject) {
      deleteProject.mutate({ requestUrl: `/api/project/${seq}` });
    }
  };
  // Delete State Handler
  useEffect(() => {
    if (deleteProject.status === "success") {
      refetchFunc();
      setShow(false);
      setIsConfirmModal(false);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProject.status]);

  // // Post Image Handler
  // const postImage = useRequestPost();
  // const handlePostImage = () => {
  //     if (seq && imageFile) {
  //         postImage.mutate({
  //             requestUrl: `/api/project/${seq}/images`,
  //             postData: imageFile,
  //         });
  //     }
  // };
  // // Image Upload Handler
  // const imageUploader = (e) => {
  //     let fileArr = e.target.files;
  //     setImageFile(Array.from(fileArr));

  //     let fileRead = new FileReader();
  //     fileRead.onload = function () {
  //         setPreviewImage(fileRead.result);
  //         console.log(fileRead.result);
  //     };

  //     fileRead.readAsDataURL(fileArr[0]);
  // };
  return (
    <>
      <div className="relative">
        <button onClick={handleShow} className="">
          <AiOutlineMore
            className={
              isShow
                ? `w-6 h-6 text-text-white bg-main-red border-2 border-main-red rounded-md`
                : `w-6 h-6 text-main-red bg-white  hover:bg-gray-lGray rounded-md cursor-pointer`
            }
          />
        </button>
        {isShow && (
          <>
            <div
              className="absolute top-[-3px] left-7 border p-1 bg-text-white rounded-lg border-main-pink flex justify-center items-center z-30"
              onClick={handleShow}
            >
              <div
                className="w-32 text-xs rounded-xl font-base p-1 "
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  type="button"
                  onClick={() => setImageUploadModal(true)}
                  className="w-full rounded-lg hover:bg-gray-sGray"
                >
                  <div className="flex items-center justify-between py-2 px-3">
                    <div>이미지 등록</div>
                    <LuImage className="text-main-pink w-4 h-4" />
                  </div>
                </button>

                <button
                  onClick={() => setIsConfirmModal(true)}
                  className="flex items-center justify-between w-full py-2 px-3 rounded-lg hover:bg-gray-sGray"
                >
                  <div>프로젝트 삭제</div>
                  <RiDeleteBin6Line className="text-main-pink w-4 h-4" />
                </button>
              </div>
            </div>
            {imageFile && <img src={previewImage} alt={"previewImage"} />}

            <div
              className="fixed top-0 left-0 z-20 w-full h-full"
              onClick={() => setShow(false)}
            ></div>
          </>
        )}
      </div>
      {isConfirmModal && (
        <DeleteConfirmModal
          tab={"project"}
          setIsConfirmModal={setIsConfirmModal}
          deleteFunc={handleDeleteProject}
        />
      )}
    </>
  );
};
export default ProjectHandleModal;
