const DefaultBtnSm = ({ content, size = 'sm', btnClickFunc }) => {
    const smStyle = 'bg-back-dGray hover:bg-main-red text-text-white py-1 px-2 rounded-[10px] text-sm';
    const mdStyle = 'bg-back-dGray hover:bg-main-red text-text-white py-2 px-2 rounded-[10px] text-sm';
    const lgStyle = 'bg-back-dGray hover:bg-main-red text-text-white py-2 px-3 rounded-[10px] text-base';
    const xlStyle = 'bg-back-dGray hover:bg-main-red text-text-white py-3 px-3 rounded-[10px] text-base';

    return (
        <button
            type="button"
            onClick={btnClickFunc}
            className={size === 'sm' ? smStyle : size === 'md' ? mdStyle : size === 'lg' ? lgStyle : xlStyle}
        >
            {content}
        </button>
    );
};

export default DefaultBtnSm;
