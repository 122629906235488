const MypageCard = ({ subTitle, title, cardCompany, cardNum }) => {
  return (
    <>
      <div className="flex gap-10 bg-white p-5 rounded-r-xl border-l-[3px] border-main-red drop-shadow-md">
        <div>
          <p className="text-sm text-gray-dGray mb-2">{subTitle}</p>
          <p className="text-lg font-semibold w-28">{title}</p>
        </div>
        {cardNum && (
          <div>
            <div className="text-xs bg-gray-sGray  w-fit px-4 py-1 rounded-md mb-2">
              {cardCompany}
            </div>
            <p className="text-text-gray text-sm">{cardNum}</p>
          </div>
        )}
      </div>
    </>
  );
};
export default MypageCard;
