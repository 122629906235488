import Grid from "@mui/material/Unstable_Grid2";

import { formatDate } from "utils/DateUtils";
import { PlanMatcherToKor, PlanStatus } from "utils/PlanMatcher";

function PlanList({ paymentInfo }) {
  return (
    <div className="mt-8">
      <Grid container spacing={1} className="text-center text-xs">
        <Grid xs={1.8}>
          <div className="bg-gray-sGray rounded-md p-3">날짜</div>
        </Grid>

        <Grid xs={4.8}>
          <div className="bg-gray-sGray rounded-md p-3">상품명</div>
        </Grid>
        <Grid xs={1.8}>
          <div className="bg-gray-sGray rounded-md p-3">금액</div>
        </Grid>
        <Grid xs={1.8}>
          <div className="bg-gray-sGray rounded-md p-3">상태</div>
        </Grid>
        <Grid xs={1.8}>
          <div className="bg-gray-sGray rounded-md p-3">영수증</div>
        </Grid>
      </Grid>
      {Array.isArray(paymentInfo) && paymentInfo.length > 0 ? (
        paymentInfo.map((item, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={1}
              className="border-b text-xs py-3"
            >
              <Grid xs={1.8}>
                <div className="text-center ">
                  {formatDate(item.localDateTime)}
                </div>
              </Grid>

              <Grid xs={4.8}>
                <div className="ml-1">
                  {PlanMatcherToKor(item.orderName)}요금제 결제
                </div>
              </Grid>
              <Grid xs={1.8}>
                <div className="text-center ">
                  {Number(item.amount).toLocaleString("ko-KR")}원
                </div>
              </Grid>
              <Grid xs={1.8}>
                <div className=" text-center ">{PlanStatus(item.status)}</div>
              </Grid>
              <Grid xs={1.8}>
                <div className="text-center ">
                  <a
                    href={item.receipt}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center block text-status-blue underline"
                    style={{
                      textDecorationThickness: "1px",
                      textUnderlineOffset: "3px",
                    }}
                  >
                    영수증
                  </a>
                </div>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <div className="text-center p-2">결제된 요금이 없습니다.</div> // 데이터가 없을 때를 위한 처리
      )}
    </div>
  );
}
export default PlanList;
