import { RxFilePlus } from "react-icons/rx";
import { LuApple, LuUser } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
const AddCard = ({ name, isActive, targetPath }) => {
  const navigate = useNavigate();
  let IconComponent;

  if (name === "브랜드") {
    IconComponent = LuApple;
  } else if (name === "타겟") {
    IconComponent = LuUser;
  } else {
    IconComponent = RxFilePlus;
  }
  const handleClick = () => {
    navigate(targetPath);
  };
  return (
    <div
      onClick={handleClick}
      className="cursor-pointer place-content-center border font-bold text-gray-gray border-gray-gray  border-dashed rounded-2xl w-[215px] h-[215px] text-center "
    >
      <IconComponent className="mb-2 m-auto w-[24px] h-[24px]" />
      <p className="text-base font-semibold">{name} 추가</p>
    </div>
  );
};

export default AddCard;
