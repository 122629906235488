import React, { useState, useEffect } from "react";
import axios from "api/axios";

import KeywordCard from "./components/KeywordCard";
import KeywordSearch from "./components/KeywordSearch";

import loadingImg from "assets/images/loadingImg.gif";

const KeywordPick = () => {
    const [keywordData, setKeywordData] = useState(null);

    useEffect(() => {
        axios
            .get("https://dev.plumbyte-ai.com/api/crawling/real-time-trends")
            .then((res) => {
                // console.log('keyword', res.data);
                setKeywordData(res.data.realTimeTrendsResponses);
                //setTimeData(res.data.referenceTime);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="w-full min-h-screen all-bg-color pb-[30px]">
            <div className="cherry-wrapper">
                <div className="mt-[6px] font-bold text-xl rounded-lg text-text-black mb-3 ml-[2px]">
                    키워드픽
                </div>
                <div className="flex mx-auto gap-[25px] ">
                    <div className="flex flex-col bg-white p-4 w-[466px] rounded-2xl">
                        <div className="flex items-center text-base text-gray-dGray font-semibold h-[40px] mb-3">
                            실시간 키워드 순위
                        </div>
                        {keywordData ? (
                            keywordData.map((item, index) => (
                                <KeywordCard
                                    key={index}
                                    ranking={item.ranking}
                                    subject={item.subject}
                                    link={item.link}
                                    icon={item.icon}
                                />
                            ))
                        ) : (
                            <div className="flex justify-center items-center h-[480px]">
                                <img src={loadingImg} alt="Loading..." />
                            </div>
                        )}
                    </div>
                    <div className="w-[656px]">
                        <KeywordSearch />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KeywordPick;
