const ModalTitle = ({ title }) => {
  return (
    <div>
      <p className="before:content-['*'] before:mr-0.5 before:text-main-pink">
        {title}
      </p>
    </div>
  );
};

export default ModalTitle;
