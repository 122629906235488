import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
export default function LandingDropdown({
  backgroundStyle = "#ffffff",
  selectValue = "",
  setSelectValue,
  itemList,
  mainCode,
  viewValueCode,
  placeholderValue = "선택 안함",
}) {
  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };
  return (
    <Box
      sx={{
        background: backgroundStyle ? backgroundStyle : "#ffffff",
        border: "1px solid #858e9630",
        borderRadius: "10px",
        position: "relative",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "13px",
            color: "#858e96 !important",
            width: "100%",

            "& .MuiSelect-select": {
              padding: "10px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-select>svg": {
              display: "none",
            },
          }}
          labelId="demo-simple-select-label"
          value={selectValue}
          IconComponent={IoIosArrowDown}
          onChange={(e) => handleChange(e)}
          displayEmpty // 기본 텍스트 표시
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "13rem",
                borderRadius: "15px",
                // boxShadow: '1', // 섀도우 제거
              },
            },
          }} // 이 부분에서 MenuItem의 너비를 20px로 제한합니다.
        >
          <MenuItem
            value=""
            disabled
            sx={{
              display: "none",
              fontSize: "13px",
              //  padding-right: 10px;
            }}
          >
            {placeholderValue}
          </MenuItem>
          {itemList.map((el, idx) => (
            // mainCode
            <MenuItem
              key={idx}
              value={el[mainCode] ? el[mainCode] : el}
              sx={
                el === selectValue
                  ? {
                      fontSize: "13px",
                      borderRadius: "5px",
                      color: "#000000",
                      background: "#F1F3F5 !important",
                      paddingY: "10px",
                    }
                  : {
                      fontSize: "13px",
                      borderRadius: "5px",
                      color: "#858e96",
                      background: "#ffffff !important",
                      "&:hover": {
                        background: "#F1F3F5 !important",
                      },
                      paddingY: "10px",
                    }
              }
            >
              {el[viewValueCode]}
              {el[mainCode] === selectValue && (
                <FaCheck className="ml-2 text-main-pink" />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
