// icons
import { IoMdArrowRoundForward } from "react-icons/io";
// hooks
import { useNavigate } from "react-router";

const BradnCard = ({ image, title, description, navigateURL }) => {
  const navigate = useNavigate();
  return (
    <button
      className="my-2 flex justify-between items-center p-4 rounded-xl"
      onClick={() => navigate(navigateURL)}
    >
      <div className="text-start flex items-center ">
        <div className="w-16 h-16 bg-text-white rounded-lg flex justify-center items-center">
          <img src={image} alt={title} className="w-10 h-10" />
        </div>
        <div className="ml-4">
          <div className="mb-1 font-bold">{`${title}`}</div>
          <div
            className=" text-main-red text-xs font-bold"
            style={{ wordBreak: "keep-all" }}
          >
            {description}
          </div>
        </div>
      </div>

      <div className="rounded-md">
        <IoMdArrowRoundForward className="w-8 h-8 text-text-lGray" />
      </div>
    </button>
  );
};

export default BradnCard;
